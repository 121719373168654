import { Injectable } from "@angular/core";

import { QuerySpec } from "@bioportal/models/QuerySpec";

import data_source_systems from "@bioportal/services/api/data_source_systems.json";

@Injectable({
  providedIn: "root",
})
export class QueryBuilderService {
  data_source_systems: any = data_source_systems;

  distinct_values_size = 200;

  //constructor() {}

  public distinct_values(): object {
    const query_spec = new QuerySpec();
    query_spec.size = this.distinct_values_size;
    query_spec.add_condition(data_source_systems);
    return query_spec.build();
  }
}

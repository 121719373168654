// Library imports
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef } from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@src/environments/environment";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

// Service imports
import { DropdownService } from "./services/dropdown.service";
import { MapsService } from "@bioportal/services/maps/maps.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { SearchFormService } from "@bioportal/services/search/search-form.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";
import { HelperService } from "@bioportal/services/helper.service";

export interface geo_json {
  municipalities: any[];
  countries: any[];
  nature: any[];
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild("basic_term") basic_term: ElementRef;

  // To keep track of the operator between fields
  operator = environment.defaultSearchOperator;
  advanced_search_enabled = false;
  geo_search_enabled = false;

  private polygon_subscription: any;

  location_filter = "";
  active_location_list: string;
  active_location = "";

  constructor(
    private helper: HelperService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    protected dropdown: DropdownService,
    protected maps: MapsService,
    protected nba: NbaService,
    protected search_form: SearchFormService,
    protected utility: UtilityService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.search_form.save_initial_values();
    this.active_location_list = "countries";
  }

  ngOnInit(): void {
    this.utility.update_meta_tags();
    // If query parameters have been provided to this component, we route directly to the overview component
    const route_params = this.route.snapshot.queryParams;
    if (!this.utility.is_empty_object(route_params)) {
      this.utility.navigate_with_params("result", route_params);
    }

    // Check if we have a saved search form field. If we do, patch the saved values
    if (!this.utility.is_empty_object(this.search_form.saved)) {
      for (const field in this.search_form.saved) {
        this.search_form.form.patchValue({
          [field]: this.search_form.saved[field],
        });
      }
      this.operator = this.search_form.saved_operator;
      this.advanced_search_enabled = !this.search_form.is_basic_search_form();
    }
    // Also, provide the correct language locations for the geosearch.
    this.maps.localize_locations();

    if (this.active_location != "" || !this.maps.no_polygon_drawn()) {
      this.geo_search_enabled = true;
    }
  }

  ngAfterViewInit() {
    // We take a subscription on the updating of the polygon. If it has been updated,
    // we will want to change the map to move to the new shape.
    this.polygon_subscription = this.maps.polygon_update.subscribe(() => {
      this.map.fitBounds(this.maps.bounds);
    });

    if (!this.advanced_search_enabled) {
      this.basic_term.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    if (this.polygon_subscription) {
      this.polygon_subscription.unsubscribe();
    }
  }

  public clear_map(): void {
    this.location_filter = "";
    this.active_location = "";
    this.maps.clear_map();
  }

  public change_location_list(list: string): void {
    if (list != this.active_location_list) {
      this.location_filter = "";
      this.active_location_list = list;
      this.maps.change_location_list(list);
    }
  }

  public enable_basic_search(): void {
    this.advanced_search_enabled = false;
  }

  protected on_advanced_search_toggle(event: any): void {
    this.search_form.reset_form();
    if (!event.checked) {
      this.change_active_source("all");
    } else {
      if (!this.dropdown.dropdowns_requested) {
        this.dropdown.get();
        this.dropdown.dropdowns_requested = true;
      }
    }
  }

  protected on_geo_search_toggle(event: any): void {
    if (!event.checked) {
      this.clear_map();
    } else {
      this.request_geo_data();
      this.maps.init_map_markers();
    }
  }

  public toggle_geo_search(): void {
    this.geo_search_enabled = !this.geo_search_enabled;
  }

  public expand_geo_search() {
    return this.geo_search_enabled || this.active_location != "" || !this.maps.no_polygon_drawn();
  }

  public hide_search_field(show_for: string[]): boolean {
    if (show_for.includes(this.search_form.active_domain) || this.search_form.active_domain === "all") {
      return false;
    }
    return true;
  }

  public list_domains(domains: string[]): string {
    if (domains.length === 3) {
      return "";
    }
    return "(" + domains.join(", ") + ")";
  }

  public get_operator(): string {
    if (!this.advanced_search_enabled) {
      return "OR";
    }
    return this.operator;
  }

  /**
   * Handles everything that needs to be done when clicking a location
   * @param id
   */
  protected select_location(id: string): void {
    this.maps.polygons = [];
    this.maps.marker_positions = [];
    this.active_location = id;
    this.maps.selected_location = id;
    this.maps.get_polygon(id);
  }

  /**
   * Processes the search procedure. Preprocesses the form by deleting any empty objects. Creates an url that
   * will be passed to the results component for further processing.
   * @param form_values that will be send to the result page
   * @author Luuk
   */
  protected process_search(form_values: any): void {
    // First, delete all empty entries from the form_values object
    form_values = this.delete_empty_object_items(form_values);

    // if form_values is an empty object
    if (this.utility.is_empty_object(form_values) && this.maps.no_location_selected() && this.maps.no_polygon_drawn()) {
      this.utility.show_error_message("errors.no_search_parameters");
      return;
    }

    // Save the form for when we route back to the search page.
    this.search_form.saved = form_values;
    this.search_form.saved_operator = this.operator;

    let parameters = {
      // Given the form values we typed in
      ...form_values,
      // The operator we want to use
      ...{ operator: this.get_operator() },
    };

    // If we have drawm a polygon, push it to the parameters
    if (this.maps.selected_location != "") {
      // Add the selected area to the parameter list
      parameters = {
        ...parameters,
        ...{ area: this.maps.selected_location },
      };
    } else if (!this.utility.is_empty_array(this.maps.polygons)) {
      // Convert the google maps encoding to nba encoding
      const polygon = this.maps.polygons[0].map(({ lat, lng }: { lat: number; lng: number }) => [lng, lat]);
      // The nba requires a closed polygon, so push the first element position to the polygon array.
      polygon.push(polygon[0]);
      // An accuracy of 1 meter is good enough, so only keep 5 decimals.
      for (const marking in polygon) {
        // The + sign removes any trailing zeroes
        polygon[marking][0] = +polygon[marking][0].toFixed(5);
        polygon[marking][1] = +polygon[marking][1].toFixed(5);
      }
      // Add the polygon to the list of parameters
      parameters = {
        ...parameters,
        ...{ polygon: polygon },
      };
    }
    // Navigate to the results page
    this.utility.navigate_with_params("result", parameters);
  }

  /**
   * Removes all form values that are empty
   * @param form_values to be pruned if empty
   * @returns pruned form values object
   * @author Luuk
   */
  private delete_empty_object_items(form_values: any): any {
    const values = structuredClone(form_values);
    for (const item in values) {
      if (values[item] == "") {
        delete values[item];
      }
    }
    return values;
  }

  protected change_active_source(source: string): void {
    this.search_form.active_domain = source;
  }

  private request_geo_data(): void {
    const query_spec = this.nba.create_geo_locations_query_spec();
    this.nba.get_data(query_spec, environment.geoDenominator).subscribe((data) => {
      this.parse_nba_geo_data(data);
      // FIXME: the button must be added once the map is loaded
      this.add_clear_button_to_map();
    });
  }

  /**
   * Returns the source systen name given the source system code.
   * @param string of source code
   * @return string of source name
   */
  protected get_source_name(source_name: string): string {
    switch (source_name) {
      case "BRAHMS":
        return "(Naturalis - Botany catalogues)";
      case "CRS":
        return "(Naturalis - Zoology and Geology catalogues)";
      case "DCSR":
        return "(Naturalis - Dutch Caribbean Species Register)";
      case "COL":
        return "(Species 2000 - Catalogue Of Life)";
      case "NSR":
        return "(Naturalis - Dutch Species Register)";
      default:
        return "";
    }
  }
  /**
   * Parses the NBA data into the correct object for Angular
   */
  private parse_nba_geo_data(data: any): void {
    if (data.totalSize > 0) {
      const geo_json: geo_json = {
        municipalities: [],
        nature: [],
        countries: [],
      };
      data.resultSet.forEach(function (entry: any) {
        const areaType: string = entry.item.areaType;

        if (entry.item.locality == "'s-Gravenhage") {
          entry.item.locality = "The Hague";
          entry.item.countryNL = "Den Haag";
        }

        if (entry.item.locality == "'s-Hertogenbosch") {
          entry.item.locality = "Den Bosch";
          entry.item.countryNL = "Den Bosch";
        }

        const area_object = {
          locality: entry.item.locality,
          countryNL: entry.item.countryNL,
          id: entry.item.id,
        };

        switch (areaType) {
          case "Municipality":
            geo_json.municipalities.push(area_object);
            break;
          case "Nature":
            // locality is the same, but includes parentheses with additional information
            area_object.countryNL = area_object.locality;
            geo_json.nature.push(area_object);
            break;
          case "Country":
            geo_json.countries.push(area_object);
            break;
          default:
            break;
        }
      });
      // Pass this retrieved data to the maps and search form services
      this.helper.geo_data = geo_json;
      this.maps.geo_data = geo_json;
      this.maps.change_location_list("countries");
    }
  }

  /**
   * Creates a control to clear the map.
   */
  public create_map_clear_button() {
    const controlButton = this.document.createElement("button");

    // Set CSS for the control.
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
    controlButton.style.fontSize = "16px";
    controlButton.style.lineHeight = "38px";
    controlButton.style.margin = "8px 8px 22px";
    controlButton.style.padding = "0 5px";
    controlButton.style.textAlign = "center";

    controlButton.textContent = this.translate.currentLang == "en" ? "Clear map" : "Kaart wissen";
    controlButton.type = "button";
    controlButton.addEventListener("click", () => {
      this.maps.clear_map();
    });
    return controlButton;
  }

  /*
   * Allows the map to have a clear button
   */
  private add_clear_button_to_map(): void {
    // Create the DIV to hold the control.
    const centerControlDiv = this.document.createElement("div");
    // Create the control.
    const centerControl = this.create_map_clear_button();
    // Append the control to the DIV.
    centerControlDiv.appendChild(centerControl);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
  }
}

<div class="multimedia-image-list mat-typography">
  <ng-container *ngFor="let image of this.images">
    <figure (click)="this.request_multimedia_item(image.sourceSystemId)">
      <div class="image-container">
        <img
          id="cy_image"
          [class]="image.is_image() ? 'multimedia-image' : ''"
          [src]="image.is_video() ? '/assets/img/play.png' : image.medium_image()"
          loading="lazy" />
      </div>
      <figcaption>
        <div class="caption-scientificname">
          {{ this.utility.capitalize_first_letter(image.identifications[0].scientificName.scientificNameGroup) }}
        </div>
        <div class="caption-sourcesystemid">{{ image.registration_number() }}</div>
      </figcaption>
    </figure>
  </ng-container>
</div>

<ng-container *ngIf="this.isDebugging()">
  <mat-expansion-panel class="debug">
    <mat-expansion-panel-header>
      <mat-panel-title>Debug NBA query </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngFor="let item of this.nba.query_spec_list">
      <p>
        <b>{{ this.utility.capitalize_first_letter(item.endpoint) }}:</b>
      </p>
      <pre>{{ item.query_spec | json }}</pre>
    </ng-container>
  </mat-expansion-panel>
</ng-container>

/**
 * This service is used for communication between components when routing.
 * For example, it retains table settings, information about urls and
 * geodata if it was retrieved in the search component.
 */

import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";

// Model imports
import { Multimedia } from "@bioportal/models/Multimedia";
import { Specimen } from "@bioportal/models/Specimen";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public multimedia_list: Multimedia[];
  public specimen_list: Specimen[] = [];
  public taxa_list: string[] = [];

  private previous_url: string;
  private current_url: string;

  public table_settings: any = {
    specimens: [],
    taxa: [],
  };

  // If we have retrieved geodata, we save it here for further use in the application
  public geo_data: any = {};
  public selected_area: any = {}; // Used to show what area was selected in the geosearch

  constructor(private router: Router) {
    this.current_url = this.router.url;
    // Helper subscription to keep track of current en previous location
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previous_url = this.current_url;
        this.current_url = event.url;
      }
    });
  }

  /**
   * We will show the specimen browse option only if we did not navigate away from
   * the multimedia item component. In essence, this means that we only allow browsing
   * when we navigated from a page that has a specimen table
   * @author Luuk
   * @return boolean
   */
  public show_specimen_item_browse(): boolean {
    return !this.previous_url.includes("/multimedia/");
  }

  /**
   * Helper functions for saving data of tables. Given the URL as a key, sorting, filtering and pagination
   * is saved to the this.table_settings object. Whenever a table is loaded, this object will be searched
   * for saved settings (and restored if found)
   */
  public table_exists(category: string): boolean {
    const target = this.table_settings[category].findIndex((obj: any) => obj.url === this.current_url);
    return target != -1;
  }

  public find_table_index(category: string): number {
    const target = this.table_settings[category].findIndex((obj: any) => obj.url === this.current_url);
    return target;
  }

  public save_table_data(category: string, table: MatTableDataSource<object>): void {
    const target = this.table_settings[category].findIndex((obj: any) => obj.url === this.current_url);
    if (target != -1) {
      this.table_settings[category].splice(target, 1);
    }
    this.table_settings[category].push({
      url: this.current_url,
      page_index: table.paginator?.pageIndex,
      page_size: table.paginator?.pageSize,
      filter: table.filter,
      sort_direction: table.sort?.direction,
      sort_column: table.sort?.active,
    });
  }

  public get_table_sort_column(category: string): string {
    return this.table_exists(category)
      ? this.table_settings[category][this.find_table_index(category)].sort_column
      : "";
  }
  public get_table_sort_direction(category: string): any {
    return this.table_exists(category)
      ? this.table_settings[category][this.find_table_index(category)].sort_direction
      : "";
  }
  public get_table_filter(category: string): string {
    return this.table_exists(category) ? this.table_settings[category][this.find_table_index(category)].filter : "";
  }
  public get_table_page_index(category: string): number {
    return this.table_exists(category) ? this.table_settings[category][this.find_table_index(category)].page_index : 0;
  }
  public get_table_page_size(category: string): number {
    return this.table_exists(category) ? this.table_settings[category][this.find_table_index(category)].page_size : 0;
  }
}

/**
 * This service handles the retrieval of dropdown menus for the advanced search. It connects to the nba to retrieve its data
 * and writes it to the Dropdowns interface. This is a public property which can be accessed from the caller.
 * @author Luuk
 */
import { environment } from "@src/environments/environment";
import { Injectable } from "@angular/core";

// Service imports
import { NbaService } from "@bioportal/services/api/nba.service";
import { UtilityService } from "@bioportal/services/utility.service";

// File imports
import nba_field_mapping from "@bioportal/services/api/nba_field_mapping.json";

export interface Dropdowns {
  [index: string]: any;
  collection_name: string[];
  license: string[];
  phase_stage: string[];
  sex: string[];
  source: string[];
  type_material: string[];
  type_status: string[];
}

@Injectable({
  providedIn: "root",
})
export class DropdownService {
  // Specifies the minimum number of items a phaseOrStage needs to be displayed in the dropdown
  private minimum_phase_stage_amount = 200;

  public dropdowns_requested = false;
  public dropdowns: Dropdowns;

  nba_field_mapping: any = nba_field_mapping;

  constructor(
    private nba: NbaService,
    private utility: UtilityService,
  ) {
    // Initialise the dropdowns interface
    this.dropdowns = {
      collection_name: [], // retrieved from nba
      type_status: [], // retrieved from nba
      type_material: [], // retrieved from nba
      phase_stage: [], // retrieved from nba
      sex: ["male", "female", "undetermined", "mixed", "hermaphrodite"],
      source: ["BRAHMS", "COL", "CRS", "DCSR", "NSR"],
      license: [
        "CC0",
        "CC BY",
        "CC BY-SA",
        "CC BY-ND",
        "CC BY-NC",
        "CC BY-NC-SA",
        "CC BY-NC-ND",
        "All rights reserved",
      ],
    } as Dropdowns;
    //};
  }

  public get(): void {
    // Request the phase_stage dropdown
    if (this.utility.is_empty_array(this.dropdowns.phase_stage)) {
      this.phase_stage();
    }
    // Request the type_material dropdown
    if (this.utility.is_empty_array(this.dropdowns.type_material)) {
      this.type_materials();
    }
    // Request the type_status dropdown
    if (this.utility.is_empty_array(this.dropdowns.type_status)) {
      this.type_status();
    }
    // Request the collection type dropdown
    if (this.utility.is_empty_array(this.dropdowns.collection_name)) {
      this.collection_names();
    }
  }

  /**
   * Retrieves the type status dropdown items from the nba
   * @author Luuk
   */
  private type_status(): void {
    this.nba
      .get_distinct_values(this.nba_field_mapping["specimens"]["type_status"], environment.specimensDenominator)
      .subscribe((data: any) => {
        this.dropdowns.type_status = Object.keys(data).sort((a: string, b: string) => a.localeCompare(b));
      });
  }

  /**
   * Retrieves the collection name dropdown items from the nba
   * @author Luuk
   */
  private collection_names(): void {
    this.nba
      .get_distinct_values(this.nba_field_mapping["specimens"]["collection_name"], environment.specimensDenominator)
      .subscribe((data) => {
        const specimen_collection_names = Object.keys(data);
        this.dropdowns.collection_name = this.dropdowns.collection_name
          .concat(specimen_collection_names)
          .sort((a: string, b: string) => a.localeCompare(b));
        this.dropdowns.collection_name = [...new Set(this.dropdowns.collection_name)];
      });
    this.nba
      .get_distinct_values(this.nba_field_mapping["multimedia"]["collection_name"], environment.multimediaDenominator)
      .subscribe((data) => {
        const multimedia_collection_names = Object.keys(data);
        this.dropdowns.collection_name = this.dropdowns.collection_name
          .concat(multimedia_collection_names)
          .sort((a: string, b: string) => a.localeCompare(b));
        this.dropdowns.collection_name = [...new Set(this.dropdowns.collection_name)];
      });
  }

  /**
   * Retrieves the phases or stages from the Nba and puts them in the corresponding dropdown
   * @author Luuk
   */
  private phase_stage(): void {
    this.nba.get_distinct_values("phaseOrStage", environment.specimensDenominator).subscribe((data: any) => {
      const phase_stage = Object.keys(data).filter((key) => data[key] > this.minimum_phase_stage_amount);
      this.dropdowns.phase_stage = phase_stage.sort((a: string, b: string) => a.localeCompare(b));
    });
  }
  /**
   * Retrieves the type materials from the Nba and puts them in the corresponding dropdown
   * @author Luuk
   */
  private type_materials(): void {
    this.nba.get_distinct_values("recordBasis", environment.specimensDenominator).subscribe((data) => {
      const record_basis = Object.keys(data);
      this.dropdowns.type_material = this.dropdowns.type_material
        .concat(record_basis)
        .sort((a: string, b: string) => a.localeCompare(b));
    });
    this.nba.get_distinct_values("kindOfUnit", environment.specimensDenominator).subscribe((data) => {
      const kind_of_unit = Object.keys(data);
      this.dropdowns.type_material = this.dropdowns.type_material
        .concat(kind_of_unit)
        .sort((a: string, b: string) => a.localeCompare(b));
    });
  }
}

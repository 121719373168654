import { Component, Input, ViewChild, AfterViewInit, OnDestroy, OnChanges } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { environment } from "@src/environments/environment";

import { NbaService } from "@bioportal/services/api/nba.service";
import { UtilityService } from "@bioportal/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "@bioportal/services/helper.service";

@Component({
  selector: "app-taxa-table",
  templateUrl: "./taxa-table.component.html",
  styleUrls: ["./taxa-table.component.scss"],
})
export class TaxaTableComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("sort") sort: MatSort;

  @Input() taxa_data: any = {};

  protected table_columns: string[] = environment.tableColumnsTaxa;
  protected table_data: MatTableDataSource<object>;
  private table_source_data: object[];

  protected show_filter = true;
  protected paginator_options: number[] = environment.paginatorOptions;

  constructor(
    protected nba: NbaService,
    protected utility: UtilityService,
    private translate: TranslateService,
    protected helper: HelperService,
  ) {}

  ngAfterViewInit(): void {
    this.table_data = new MatTableDataSource(this.table_source_data);
    this.table_data.paginator = this.paginator;
    this.table_data.sort = this.sort;
    // Restore the previous filter, paginator and sorter
    if (this.helper.table_exists(environment.taxaDenominator)) {
      this.table_data.filter = this.helper.get_table_filter(environment.taxaDenominator);
      this.paginator.pageIndex = this.helper.get_table_page_index(environment.taxaDenominator);
      this.paginator.pageSize = this.helper.get_table_page_size(environment.taxaDenominator);
      this.sort.active = this.helper.get_table_sort_column(environment.taxaDenominator);
      this.sort.direction = this.helper.get_table_sort_direction(environment.taxaDenominator);
    }
  }

  ngOnChanges() {
    this.table_data = new MatTableDataSource(this.table_source_data);
    // Whenever a change occurs, check if we need to show the filter
    this.fill_table(this.taxa_data.resultSet);
    this.show_filter = this.taxa_data.resultSet.length > environment.table_filter_cutoff;
  }

  ngOnDestroy() {
    this.helper.save_table_data(environment.taxaDenominator, this.table_data);
  }

  private get_popular_name(names: any) {
    const preferred_language = this.translate.currentLang === "nl" ? "Dutch" : "English";
    if (names) {
      for (const name of names) {
        if (name.language === preferred_language) {
          return name.name;
        }
      }
    }
    return "";
  }

  private create_description(item: any) {
    let description = "";
    if (item.synonyms && item.synonyms.length > 0) {
      description += this.translate.instant("taxa.synonyms") + ": ";
      let first = true;
      for (const synonym of item.synonyms) {
        if (first) {
          first = false;
        } else {
          description += "; ";
        }
        description += synonym.fullScientificName;
      }
    } else {
      if (item.taxonRank === "species" || item.taxonRank === "subspecies") {
        const translation = this.translate.instant("taxa." + item.taxonRank);
        description = translation;
      }
    }
    return description;
  }

  /**
   * Function to fill the taxa table with data
   * @param data received from the API
   * @author Luuk
   */
  private fill_table(data: any) {
    this.table_source_data = [];
    for (const i in data) {
      // Create an object for the table with the specific fields we need
      const table_object: any = {
        name: data[i].item.acceptedName.fullScientificName,
        popular_name: "",
        description: "",
        taxon_rank: data[i].item.taxonRank,
        found_in: data[i].item.sourceSystem.name,
        full_data: data[i], // to allow easy opening of dialogs
      };
      // Provide the multiple species identifications in a list as the material table requires
      table_object["popular_name"] = this.get_popular_name(data[i].item.vernacularNames);
      table_object["description"] = this.create_description(data[i].item);
      this.table_source_data.push(table_object);
    }
  }

  /**
   * Applies the sorting and filtering of the given table. Handles pagination if necessary.
   * @param event handles the filtering if provided
   * @param table that needs to be sorted/filtered
   * @author Luuk
   */
  public apply_sort_filter(event: Event, table: MatTableDataSource<object>): void {
    const filterValue = (event.target as HTMLInputElement).value;
    table.filter = filterValue.toLowerCase();

    if (table.paginator) {
      table.paginator.firstPage();
    }
  }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@src/environments/environment";

import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "@bioportal/services/local-storage.service";
import { Location } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private localStore: LocalStorageService,
    private location: Location,
    private meta: Meta,
    private title: Title,
  ) {}

  public get_formatted_query_result_size(size: number): string {
    return size > 0 ? `(${size.toLocaleString(this.translate.currentLang)})` : "";
  }

  /**
   * Updates the meta tag of the page
   * @param string of title to use for the meta tag
   */
  public update_meta_tags(page_title?: string) {
    const title = page_title ? page_title + " | " + environment.appTitle : environment.appTitle;
    this.title.setTitle(title);
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });
    const content = title;
    this.meta.updateTag({
      name: "description",
      content: this.strip_html_tags(content),
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.strip_html_tags(content),
    });
    const location = new URL(this.location.path(), environment.baseUrl);
    this.meta.updateTag({ name: "url", content: location.toString() });
    this.meta.updateTag({ property: "og:url", content: location.toString() });
  }

  public is_empty_object(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  /**
   * Returns true if the given value can be interpreted as a 'true' boolean.
   * Otherwise returns false.
   * @param value that is to be interpreted
   * @returns boolean
   * @author Luuk
   */
  public get_boolean(value: any) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks whether the given array is empty
   * @param array
   * @returns boolean: true if array is empty
   * @author Luuk
   */
  public is_empty_array(array: any): boolean {
    if (Array.isArray(array) && array.length) {
      return false;
    } else {
      return true;
    }
  }

  public click_prevented_navigate_to(destination: string, event: MouseEvent) {
    event.preventDefault();
    this.router.navigate([destination]);
  }
  /**
   * Navigate to the destination given optional parameter string
   * @param destination
   * @param parameter (optional)
   * @author Luuk
   */
  public navigate_to(destination: string, parameter?: string): void {
    destination = `${this.translate.currentLang}/${destination}`;

    if (parameter) {
      parameter = parameter.replaceAll(" ", "_");
    }
    parameter ? this.router.navigate([destination, parameter]) : this.router.navigate([destination]);
  }

  /**
   * Navigate to the destination given optional parameters
   * @param destination
   * @param params
   * @author Luuk
   */
  public navigate_with_params(destination: string, params: object): void {
    const navigation_link = `/${this.translate.currentLang}/${destination}`;
    this.router.navigate([navigation_link], { queryParams: params });
  }

  /**
   * Function to retrieve the master image instead of the default large one
   * @param image string in which the word large will be replaced by medium
   * @returns string
   * @author Joep
   */
  public master_image(image: any): string {
    const re = /large$/gi;
    if (image.match(re)) {
      return image.replace(re, "master");
    }
    return "";
  }

  /**
   * Capitalizes the first word of a string
   * @param string
   * @returns capitalized string
   * @author Luuk
   */
  public capitalize_first_letter(string: string): string {
    if (string != undefined) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  }

  /**
   * Opens Material popup window with the given message
   * @param message information that is showed in the popup
   * @author Luuk
   */
  public open_snackbar(message: string): void {
    this.snackBar.open(message, "Close", {
      duration: 5000,
    });
  }

  /**
   * Takes the error, which is a translate-service key, an shows
   * it inside a snackbar on screen
   * @param error string containing a translate-service key
   * @author Luuk
   */
  public show_error_message(error: string): void {
    this.translate.get(error).subscribe((res: string) => {
      this.open_snackbar(res);
    });
  }

  /**
   * Allows the calling of a translation with a variable from a loop for example
   * @param category of the translation
   * @param field we want to access
   * @returns string containing the translation call string
   * @author Luuk
   */
  public translation_with_variable(category: string, field: string): string {
    return `${category}.${field}`;
  }

  /**
   * Checks if given object is a string
   * @param x
   * @returns boolean
   */
  public isString(x: any) {
    return Object.prototype.toString.call(x) === "[object String]";
  }

  public strip_html_tags(str: string): string {
    if (str === null || str === "") return "";
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  }

  /**
   * Removes all fields that are empty from the given object
   * @param obj (object) to be pruned if empty
   * @returns object that has been pruned
   * @author Luuk
   */
  public remove_empty_fields_from_object(obj: object): object {
    const cloned_obj: any = structuredClone(obj);
    for (const field in cloned_obj) {
      if (cloned_obj[field] == "") {
        delete cloned_obj[field];
      }
    }
    return cloned_obj;
  }

  /**
   * Sets every boolean property in the given object to the provided boolean
   * @param obj (object)
   * @returns object
   * @author Luuk
   */
  public set_object_booleans(obj: any, bool: boolean): any {
    for (const key in obj) {
      if (typeof obj[key] === "boolean") {
        obj[key] = bool;
      }
    }
    return obj;
  }
}

export class DefaultClassification {
  className = "—";
  family = "—";
  genus = "—";
  infraSpecificEpithet = "—";
  kingdom = "—";
  order = "—";
  phylum = "—";
  specificEpithet = "—";
  subOrder = "—";

  constructor(content: any) {
    this.set(content);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param content with JSON data received from the server
   * @author Luuk
   */
  public set(content: any) {
    this.className = "className" in content ? content["className"] : "";
    this.family = "family" in content ? content["family"] : "";
    this.genus = "genus" in content ? content["genus"] : "";
    this.infraSpecificEpithet = "infraSpecificEpithet" in content ? content["infraSpecificEpithet"] : "";
    this.kingdom = "kingdom" in content ? content["kingdom"] : "";
    this.order = "order" in content ? content["order"] : "";
    this.phylum = "phylum" in content ? content["phylum"] : "";
    this.specificEpithet = "specificEpithet" in content ? content["specificEpithet"] : "";
    this.subOrder = "subOrder" in content ? content["subOrder"] : "";
  }
}

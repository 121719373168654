// TODO: rename this class to something more apt.

import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "@src/environments/environment";
import { Params } from "@angular/router";
import { Router } from "@angular/router";

// Service imports
import { NbaService } from "@bioportal/services/api/nba.service";
import { UtilityService } from "@bioportal/services/utility.service";

// Model imports
import { Search_data } from "@bioportal/models/Search_data";

export declare type KeyBoolean = {
  [key: string]: boolean;
};
@Injectable({
  providedIn: "root",
})
export class SearchFormService {
  // Search form and all its fields
  form: any = new FormGroup({
    basic_term: new FormControl(""),
    scientific_name: new FormControl(""), //, [Validators.pattern("[a-zA-Z .-]*")]),
    common_name: new FormControl(""),
    family: new FormControl(""),
    genus: new FormControl(""),
    epithet: new FormControl(""),
    registration_number: new FormControl(""),
    source: new FormControl(""),
    collection_name: new FormControl(""),
    type_status: new FormControl(""),
    type_material: new FormControl(""),
    locality: new FormControl(""),
    phase_stage: new FormControl(""),
    sex: new FormControl(""),
    collector: new FormControl(""),
    collector_field_number: new FormControl(""),
    license: new FormControl(""),
    kingdom: new FormControl(""),
    phylum: new FormControl(""),
    class: new FormControl(""),
    order: new FormControl(""),
    subgenus: new FormControl(""),
    infraspecific_name: new FormControl(""),
    old_barcodes: new FormControl(""),
    chronostratigraphy: new FormControl(""),
    lithostratigraphy: new FormControl(""),
    biostratigraphy: new FormControl(""),
  });

  operators: any = {};

  geo_data: any;
  selected_area: any = {};

  constructor(
    protected nba: NbaService,
    private router: Router,
    protected utility: UtilityService,
  ) {}

  // Keep track whether we already saved our initial values
  init = false;
  initial_values: object;
  active_domain = "all";

  // Variable to save a form for later retrieval
  public saved: any = {};
  public saved_operator: string;
  /**
   * Saves initial values of the form for easy resetting
   * @author Luuk
   */
  public save_initial_values() {
    if (!this.init) {
      // If we have not yet initialized, do so.
      this.initial_values = this.form.value;
      this.init = true;
    }
  }

  /**
   * Resets the form to its initial values
   * @author Luuk
   */
  public reset_form() {
    for (const field in this.form.value) {
      this.form.patchValue({ [field]: "" });
    }
    this.saved = {};
  }

  public clear_basic_term() {
    this.form.patchValue({ basic_term: "" });
  }

  /**
   * If the basic term is used, we will fill all form values with this field data
   * to be able to search the entire NBA on that term
   * @param form_values with the basic term provided
   * @returns form_values object with basic term in all its fields
   * @author Luuk
   */
  public search_basic_term(form_values: any): object {
    const basic_term: string = form_values["basic_term"];
    delete form_values["basic_term"];
    for (const item in form_values) {
      form_values[item] = basic_term;
    }
    return form_values;
  }

  public is_basic_search_form(): boolean {
    return this.form.value["basic_term"] != "";
  }

  /**
   * Removes all form values that are empty from the given object
   * @param form_values to be pruned if empty
   * @returns pruned form values object
   * @author Luuk
   */
  public remove_empty_form_fields(form_values: object): object {
    const form: any = structuredClone(form_values);
    for (const field in form) {
      if (form[field] == "") {
        delete form[field];
      }
    }
    return form;
  }

  /**
   * Form entries are strings. This function takes the form values and puts them
   * into arrays. (useful for splitting on whitespace with nba improvements)
   * @param form_values
   * @returns form_values with their strings replaced by lists of strings
   * @author Luuk
   */
  private put_strings_inside_array(form_values: object): object {
    const form: any = structuredClone(form_values);
    for (const field in form) {
      // We only want to split strings on whitespace. Leave arrays be
      if (this.utility.isString(form[field])) {
        if (form[field]) {
          form[field] = [form[field].replaceAll("_", " ")];
        }
      }
    }
    return form;
  }

  /**
   * Converts the polygon provided to the URL to a usable nba polygon
   * @param polygon
   * @author Luuk
   */
  public resolve_polygon_string(raw_polygon: string): any {
    const resolved_polygon: any[] = [];

    let odd_string = "";
    let counter = 0;
    const split_string = raw_polygon.split(",");

    // Now we need to put the lng,lat per pair in a list
    for (const i in split_string) {
      counter += 1;
      if (counter % 2 == 0) {
        resolved_polygon.push([parseFloat(odd_string), parseFloat(split_string[i])]);
        odd_string = "";
      }
      odd_string = split_string[i];
    }
    return resolved_polygon;
  }

  public resolve_polygon(polygon: any[]): any {
    const resolved_polygon: any[] = [];
    for (const i in polygon) {
      const coordinates = polygon[i].split(",");
      resolved_polygon.push([coordinates[0], coordinates[1]]);
    }
    return resolved_polygon;
  }

  /**
   * Creates an empty search object
   */
  public create_search_data_object(): Search_data {
    return new Search_data();
  }

  /**
   * Retrieves the save identifier from the url.
   * @param url (string)
   * @return string
   */
  private get_save_identifier(url: string): string {
    url = url.replace("/en/", "");
    url = url.replace("/nl/", "");
    return url;
  }

  /**
   * Function to handle the URL parameters by setting the search form
   * with the provided data. Also sets the correct operator.
   * TODO: move this to the url-helper and refactor to interpret().
   * @returns Search_data
   * @author Luuk
   */
  public parse_url_parameters(params: Params): Search_data {
    const search_data = new Search_data();
    search_data.url = this.router.url;
    search_data.save_identifier = this.get_save_identifier(search_data.url);
    search_data.size = environment.defaultQuerySize;

    if ("s_scientificName" in params) {
      params = this.process_legacy_url(params);
    }

    if (params["polygon"]) {
      search_data.geosearch = true;
      if (typeof params["polygon"] == "string") {
        search_data.polygon = this.resolve_polygon_string(params["polygon"]);
      } else {
        search_data.polygon = this.resolve_polygon(params["polygon"]);
      }
    }

    if (params["area"]) {
      search_data.geosearch = true;
      search_data.area = params["area"];
      // Find name corresponding to the area. TODO: needs to be in env.
      const categories = ["countries", "municipalities", "nature"];

      // Make sure that we have geo data before handling these requests
      // FIXME: make sure the application always posesses geo data before running this function
      if (this.geo_data) {
        for (const i in categories) {
          const res = this.geo_data[categories[i]].find((i: any) => i.id === search_data.area);
          if (res) {
            this.selected_area = res;
            break;
          }
        }
      } else {
        this.selected_area = "";
      }
    }

    for (const field in search_data.search_fields) {
      if (params[field]) {
        search_data.search_fields[field] = params[field];
      }
    }

    search_data.using_basic_term = search_data.search_fields.basic_term == "" ? false : true;
    search_data.search_fields = this.format_form_for_basic_search(search_data.search_fields);
    search_data.search_fields = this.put_strings_inside_array(search_data.search_fields);
    search_data.page_type = params["page_type"] ? params["page_type"] : "url_query";
    search_data.search_term = params["id"] ? params["id"] : "";
    search_data.operator = params["operator"] ? params["operator"] : environment.defaultOperator;

    return search_data;
  }

  /**
   * Makes the form ready for processing
   * @param source string denoting specimen, taxon or multimedia item
   * @returns query spec created from the form and the provided source
   * @author Luuk
   */
  format_form_for_basic_search(form_values: any): any {
    if (form_values.basic_term != "") {
      form_values = this.search_basic_term(form_values);
    } else {
      form_values = this.remove_empty_form_fields(form_values);
    }
    return form_values;
  }

  /**
   * If a legacy URL has been detected, we need to convert the old query params to the new ones.
   * @param params object with legacy query parameters
   * @returns object with current query parameters
   */
  private process_legacy_url(params: Params): Params {
    const resolved_params: any = {
      type_status: [],
      type_material: [],
      license: [],
      source: [],
    };
    let operator = environment.defaultOperator;

    for (const key in params) {
      const value = params[key];

      if (value) {
        if (key.includes("scientificName")) {
          resolved_params["scientific_name"] = value;
        } else if (key.includes("term")) {
          resolved_params["basic_term"] = value;
        } else if (key.includes("vernacularName")) {
          resolved_params["common_name"] = value;
        } else if (key.includes("genusOrMonomial")) {
          resolved_params["genus"] = value;
        } else if (key.includes("specificEpithet")) {
          resolved_params["epithet"] = value;
        } else if (key.includes("unitID")) {
          resolved_params["registration_number"] = value;
        } else if (key.includes("sourceSystem")) {
          resolved_params["source"] = value;
        } else if (key.includes("collectionType")) {
          resolved_params["collection_name"].push(value);
        } else if (key.includes("localityText")) {
          resolved_params["locality"] = value;
        } else if (key.includes("phaseOrStage")) {
          resolved_params["phase_stage"] = value;
        } else if (key.includes("sex")) {
          resolved_params["sex"] = value;
        } else if (key.includes("gatheringAgent")) {
          resolved_params["collector"] = value;
        } else if (key.includes("collectorsFieldNumber")) {
          resolved_params["collector_field_number"] = value;
        } else if (key.includes("kingdom")) {
          resolved_params["kingdom"] = value;
        } else if (key.includes("phylum")) {
          resolved_params["phylum"] = value;
        } else if (key.includes("className")) {
          resolved_params["class"] = value;
        } else if (key.includes("order")) {
          resolved_params["order"] = value;
        } else if (key.includes("infraspecificEpithet")) {
          resolved_params["infraspecific_name"] = value;
        } else if (key.includes("previousUnitsText")) {
          resolved_params["old_barcodes"] = value;
        } else if (key.includes("chronoStratigraphy")) {
          resolved_params["chronostratigraphy"] = value;
        } else if (key.includes("lithoStratigraphy")) {
          resolved_params["lithostratigraphy"] = value;
        } else if (key.includes("bioStratigraphy")) {
          resolved_params["biostratigraphy"] = value;
        } else if (key.includes("typeStatus")) {
          resolved_params["type_status"].push(value);
        } else if (key.includes("kindOfUnit")) {
          resolved_params["type_material"] = value;
        } else if (key.includes("subgenus")) {
          resolved_params["subgenus"] = value;
        } else if (key.includes("license")) {
          resolved_params["license"].push(value);
        } else if (key.includes("language")) {
          resolved_params["language"] = value;
        } else if (key.includes("andOr")) {
          operator = value == 1 ? "OR" : "AND";
        } else if (key.includes("gid")) {
          const gid = value.split("_");
          resolved_params["area"] = `${gid[gid.length - 1]}@GEO`;
        }
      }
    }

    // Delete the created lists if they return empty
    if (!resolved_params.type_status?.length) {
      delete resolved_params.type_status;
    }
    if (!resolved_params.type_material?.length) {
      delete resolved_params.type_material;
    }
    if (!resolved_params.license?.length) {
      delete resolved_params.license;
    }
    if (!resolved_params.source?.length) {
      delete resolved_params.source;
    }

    resolved_params["operator"] = operator;
    return resolved_params;
  }
}

// Library imports
import { NgModule, TransferState } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { GoogleMapsModule } from "@angular/google-maps";
import { GoogleChartsModule } from "angular-google-charts";

import { translateBrowserLoaderFactory } from "./services/translate/translate-browser.service";

// Component imports
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ResultsComponent } from "./pages/results/results.component";
import { HighlightsComponent } from "./pages/highlights/highlights.component";
import { MultimediaItemComponent } from "./pages/multimedia-item/multimedia-item.component";
import { MultimediaListComponent } from "./pages/results/multimedia-list/multimedia-list.component";
import { SpecimenItemComponent } from "./pages/specimen-item/specimen-item.component";
import { TaxonItemComponent } from "./pages/taxon-item/taxon-item.component";
import { SpecimenTableComponent } from "./components/specimen-table/specimen-table.component";
import { TaxaTableComponent } from "./components/taxa-table/taxa-table.component";
import { ContentComponent } from "./pages/content/content.component";
import { QueryPrinterComponent } from "./components/query-printer/query-printer.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { MultimediaViewerComponent } from "./components/multimedia-viewer/multimedia-viewer.component";

// Pipes imports
import { FilterPipe } from "./pipes/filter.pipe";

// Material Imports
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SearchComponent } from "./pages/search/search.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { GoogleTagManagerModule } from "angular-google-tag-manager";

import { environment } from "@src/environments/environment";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BioportalComponent } from "./bioportal/bioportal.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    HighlightsComponent,
    MultimediaItemComponent,
    MultimediaListComponent,
    MultimediaViewerComponent,
    SpecimenItemComponent,
    TaxonItemComponent,
    ContentComponent,
    SearchComponent,
    FooterComponent,
    SpecimenTableComponent,
    TaxaTableComponent,
    QueryPrinterComponent,
    DashboardComponent,
    NotFoundComponent,
    FilterPipe,
    BioportalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: environment.defaultLanguage,
    }),
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    GoogleChartsModule,

    // Material imports
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    NgbModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatListModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    GoogleTagManagerModule,
  ],
  providers: [
    provideHttpClient(withFetch()),
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: "googleTagManagerId",
      useValue: environment.googleTagManagerId,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

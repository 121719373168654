<div>
  <h1>Sorry,</h1>
  <h3>{{ "not_found.header" | translate }}</h3>
  <p>{{ "not_found.body" | translate }}</p>
  <a class="a-no-redirect" (click)="this.navigator.navigate('')">
    {{ "not_found.link" | translate }}
  </a>
  <hr />
  <img src="/assets/img/dodo.png" />
</div>

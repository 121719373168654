import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class NavigatorService {
  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {}

  /**
   * Navigates to the result page of the given endpoint.
   * @param endpoint (string)
   * @param params (object)
   * @author Luuk
   */
  public load_all(endpoint: string, params: any): void {
    const navigation_link = `/${this.translate.currentLang}/result/${endpoint}`;
    this.router.navigate([navigation_link], { queryParams: params });
  }

  public navigate(location: string): void {
    const navigation_link = `/${this.translate.currentLang}/${location}`;
    this.router.navigate([navigation_link]);
  }

  public navigate_to_specimen_item(identifier: string): void {
    this.navigate(`specimen/${identifier}`);
  }
  public navigate_to_multimedia_item(identifier: string): void {
    this.navigate(`multimedia/${identifier}`);
  }
}

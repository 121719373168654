<app-query-printer></app-query-printer>
<div style="padding: 1em" *ngIf="this.isFound">
  <h1>Taxon</h1>
  <!-- Show the name of the taxon. Is the same for all taxa, so just list the first one  -->
  <h2 *ngIf="this.current_taxa?.length">
    <i>{{ this.utility.capitalize_first_letter(this.current_taxa[0].scientific_name_group) }} </i
    >{{ this.current_taxa[0].authorship_verbatim }}
  </h2>

  <h3 class="pt-2">
    {{ "taxa.common_name" | translate }}
  </h3>
  <ng-container *ngIf="this.no_vernaculars; then vernaculars_empty; else vernaculars_content"></ng-container>

  <ng-template #vernaculars_content>
    <ng-container *ngFor="let taxon of this.current_taxa">
      <ng-container *ngIf="taxon.vernaculars?.length">
        <p>
          <b
            ><a href="{{ taxon.record_URI }}" target="_blank" id="cy_vernacular_source"
              >{{ taxon.source_system_name }} ({{ taxon.source_system_code }})</a
            ></b
          >
        </p>

        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let vernacular of taxon.vernaculars">
              <td class="col-md-3">
                {{ vernacular["language"] }}
              </td>
              <td class="col-md-9" id="cy_vernacular_name">
                {{ vernacular["name"] }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #vernaculars_empty>
    <p>{{ "taxa.no_vernaculars" | translate }}</p>
  </ng-template>

  <!-- Synonyms -->
  <h3>
    {{ "taxa.synonyms" | translate }}
  </h3>
  <ng-container *ngIf="this.no_synonyms; then synonyms_empty; else synonyms_content"></ng-container>

  <ng-template #synonyms_content>
    <ng-container *ngFor="let taxon of this.current_taxa">
      <ng-container *ngIf="taxon.has_synonyms">
        <p>
          <b>{{ taxon.source_system_name }}</b>
        </p>
        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let synonym of taxon.synonyms">
              <td id="cy_synonym">
                {{ synonym.fullScientificName }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #synonyms_empty>
    <p>{{ "taxa.no_synonyms" | translate }}</p>
  </ng-template>

  <!-- Descriptions -->
  <h3 class="pt-2">
    <b>{{ "taxa.descriptions" | translate }}</b>
  </h3>

  <ng-container *ngIf="this.no_descriptions; then descriptions_empty; else descriptions_content"></ng-container>

  <ng-template #descriptions_empty>
    <p id="cy_no_descriptions">{{ "taxa.no_descriptions" | translate }}</p>
  </ng-template>

  <ng-template #descriptions_content>
    <ng-container *ngFor="let taxon of this.current_taxa">
      <ng-container *ngIf="taxon.has_description">
        <p>
          <b
            ><a href="{{ taxon.record_URI }}" target="_blank"
              >{{ taxon.source_system_name }} ({{ taxon.source_system_code }})</a
            ></b
          >
        </p>

        <ng-container *ngIf="this.translate.currentLang === 'en'">
          <div id="cy_description_en" [innerHTML]="taxon.description_en"></div>
        </ng-container>

        <ng-container *ngIf="this.translate.currentLang === 'nl'">
          <div id="cy_description_nl" [innerHTML]="taxon.description_nl"></div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <!-- Classifications -->
  <ng-container
    *ngIf="this.no_classifications; then classifications_empty; else classifications_content"></ng-container>

  <ng-template #classifications_empty>
    <p>{{ "taxa.no_classifications" | translate }}</p>
  </ng-template>

  <ng-template #classifications_content>
    <h3 class="pt-2">{{ "taxa.classifications" | translate }}</h3>
    <ng-container *ngFor="let taxon of this.current_taxa">
      <ng-container *ngIf="taxon.has_classification">
        <p>
          <b>
            <a href="{{ taxon.record_URI }}" target="_blank"
              >{{ taxon.source_system_name }} ({{ taxon.source_system_code }})</a
            >
          </b>
        </p>

        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let classification of taxon.classifications">
              <td class="col-md-3">
                {{ this.utility.translation_with_variable("search", classification.rank) | translate }}
              </td>
              <td class="col-md-9" id="cy_classification">
                {{ classification.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="this.specimen_data.totalSize > 0">
    <h3 class="pt-2">Specimens</h3>
    <app-specimen-table id="cy_specimen_table" [specimen_data]="this.specimen_data"></app-specimen-table>
    <div style="padding: 0.5em">
      <a class="a-no-redirect" (click)="this.load_all_specimen()">
        {{ "search.show_all" | translate }} {{ this.specimen_data.totalSize }} {{ "search.results" | translate }}.
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="this.multimedia_data.totalSize > 0">
    <h3 class="pt-2">
      <b>Multimedia</b>
    </h3>
    <app-multimedia-viewer
      id="cy_multimedia_list"
      (multimedia_item_request)="this.navigator.navigate_to_multimedia_item($event)"
      [images]="this.multimedia_data.result_set"></app-multimedia-viewer>
    <div style="padding: 0.5em">
      <a class="a-no-redirect" (click)="this.load_all_multimedia()">
        {{ "search.show_all" | translate }} {{ this.multimedia_data.totalSize }} {{ "search.results" | translate }}.
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="this.current_taxa?.length">
    <h3 class="pt-2">
      {{ "taxa.literature" | translate }}
    </h3>

    <p>
      {{ "taxa.find_literature_1" | translate }}
      <i>{{ this.utility.capitalize_first_letter(this.current_taxa[0].scientific_name_group) }}</i>
      {{ "taxa.find_literature_2" | translate }}
    </p>

    <table class="table table-striped">
      <tbody>
        <tr>
          <td><a href="{{ this.current_taxa[0].worldcat_link }}" target="_blank">WorldCat</a></td>
        </tr>
        <tr>
          <td><a href="{{ this.current_taxa[0].bhl_link }}" target="_blank">Biodiversity Heritage Library</a></td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<div style="padding: 1em" *ngIf="this.isFound === false">
  <h1>{{ "taxa.not_found_title" | translate }}</h1>
  <p>{{ "taxa.not_found_body" | translate }}</p>
</div>

// Library imports
import { Component, OnInit, Input } from "@angular/core";
import { environment } from "@src/environments/environment";

// Service imports
import { NavigatorService } from "@bioportal/services/navigator.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { StorageService } from "@bioportal/services/storage.service";
import { UtilityService } from "@bioportal/services/utility.service";

// Model imports
import { Multimedia } from "@bioportal/models/Multimedia";
import { Search_data } from "@bioportal/models/Search_data";

@Component({
  selector: "app-multimedia-list",
  templateUrl: "./multimedia-list.component.html",
  styleUrls: ["./multimedia-list.component.scss"],
})
export class MultimediaListComponent implements OnInit {
  @Input() search_data: Search_data;

  protected images: Multimedia[] = [];

  // Keep track whether a request for data is pending
  protected requesting = true;

  private currentPage = 0;
  private itemsPerPage = environment.multimediaItemsPerPage;

  // Reusable queryspec for infinite scrolling
  private query_spec: any = {};

  constructor(
    private storage: StorageService,
    protected navigator: NavigatorService,
    protected nba: NbaService,
    protected utility: UtilityService,
  ) {}

  /**
   *  On init, load the first batch of multimedia items.
   */
  ngOnInit(): void {
    // If we are scrolling through images
    this.utility.update_meta_tags("Multimedia");

    // Make sure we always have a query_spec. Otherwise, on scroll might request multimedia with an empty object.
    this.query_spec = this.nba.convert_search_data_to_query_spec(this.search_data, environment.multimediaDenominator);

    // Request the data. We first check the storage, using the current url as the identifier.
    // If we need to request the nba for extra data, we will add this to the storage in the request_multimedia function
    if (this.storage.has(environment.multimediaDenominator, this.search_data.save_identifier)) {
      this.images = this.storage.get(environment.multimediaDenominator, this.search_data.save_identifier);
    } else {
      this.request_multimedia(this.query_spec);
    }
  }

  /**
   * Processes the steps necessary when page is scrolled down. NB: this function is called when the page is initialised
   * @author Joep
   */
  protected onScroll(): void {
    this.currentPage++;
    this.query_spec = this.nba.page_query_spec(this.query_spec, this.currentPage, this.itemsPerPage);
    this.request_multimedia(this.query_spec);
  }

  /**
   * Handles the NBA request for (more) data
   * @author Luuk
   */
  private request_multimedia(query_spec: any): void {
    this.requesting = true;
    // Take the previous query_spec for multimedia and edit it with our new from-to scrolling information
    query_spec = this.nba.page_query_spec(this.query_spec, this.currentPage, this.itemsPerPage);
    this.nba.get_data(query_spec, environment.multimediaDenominator, false).subscribe((data) => {
      data.result_set.forEach((item: Multimedia) => {
        this.images.push(item);
      });
      this.storage.save(environment.multimediaDenominator, this.images, this.search_data.save_identifier);
      this.requesting = false;
    });
  }
}

/**
 * This component handles the viewing of highlights and special collections
 */

// Library imports
import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

// Service imports
import { DrupalService } from "@bioportal/services/api/drupal.service";
import { NavigatorService } from "@bioportal/services/navigator.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";

// Model imports
import { Content } from "@bioportal/models/Content";
import { Search_data } from "@bioportal/models/Search_data";

import { environment } from "@src/environments/environment";

@Component({
  selector: "app-highlights",
  templateUrl: "./highlights.component.html",
  styleUrls: ["./highlights.component.scss"],
})
export class HighlightsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() search_data: Search_data;
  @Input() is_server: boolean;
  @Output() page_not_found = new EventEmitter<void>();

  protected specimen_data: any = [];
  protected multimedia_data: any = [];

  private language_change_subscription: Subscription;

  current_content: Content;
  feature: any;
  isFound = false;

  nba_search_term = "";

  constructor(
    private drupal: DrupalService,
    protected navigator: NavigatorService,
    protected nba: NbaService,
    protected translate: TranslateService,
    private meta: Meta,
    private title: Title,
    protected utility: UtilityService,
  ) {}

  ngOnInit(): void {
    // We need to keep track of language changes to retrieve the Drupal content in another language
    this.language_change_subscription = this.translate.onLangChange.subscribe({
      next: () => {
        this.load_content();
      },
    });
  }

  ngOnChanges() {
    this.load_content();
  }

  ngOnDestroy(): void {
    this.language_change_subscription.unsubscribe();
  }

  /**
   * Retrieves both Drupal and NBA data for the current highlight
   * @author Joep
   */
  private load_content() {
    const featured = new Map<string, any>();
    // The correct Drupal url is a combination of the current language, the 'highlights' keyword and the slug of the content page
    const drupal_url = `/${this.translate.currentLang}/${this.search_data.params.page}/${this.search_data.params.endpoint}`;

    this.drupal.query_featured_content().subscribe({
      next: (result) => {
        for (const i in result.data) {
          featured.set(result.data[i].attributes.path.alias, result.data[i].attributes);
        }
        this.current_content = new Content();
        const featured_content = featured.get(drupal_url);
        if (featured_content) {
          this.isFound = true;
          this.nba_search_term = featured_content.field_nba_search_term;
          this.current_content.set(featured_content);
          this.update_meta_tags(drupal_url);

          // Retrieve data for multimedia
          let query_spec = this.nba.get_collection_queryspec(environment.multimediaDenominator, this.nba_search_term);
          this.request_multimedia(query_spec);

          // Retrieve data for specimens
          query_spec = this.nba.get_collection_queryspec(environment.specimensDenominator, this.nba_search_term);
          this.request_specimen(query_spec);
        } else {
          this.page_not_found.emit();
        }
      },
    });
  }

  private request_specimen(query_spec: any): void {
    this.nba.get_data(query_spec, environment.specimensDenominator).subscribe((data) => {
      this.specimen_data = data;
    });
  }
  private request_multimedia(query_spec: any): void {
    this.nba.get_data(query_spec, environment.multimediaDenominator).subscribe((data) => {
      this.multimedia_data = data;
    });
  }

  /**
   * TODO: merge this function with the one in utility service.
   */
  public update_meta_tags(drupal_url: string): void {
    const title = this.current_content.title + " | " + environment.appTitle;
    this.title.setTitle(title);
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });
    const content = "Highlight of Naturalis Bioportal: " + this.current_content.body;
    this.meta.updateTag({
      name: "description",
      content: this.utility.strip_html_tags(content),
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.utility.strip_html_tags(content),
    });

    const location = new URL(drupal_url, environment.baseUrl);
    this.meta.updateTag({ name: "url", content: location.toString() });
    this.meta.updateTag({ property: "og:url", content: location.toString() });
  }

  /**
   * Loads all results using the saved query and the new amount of hits requested
   * @param category for which to load the results
   * @param amount of hits we want to retrieve
   * @author Luuk
   */
  protected load_all_specimens(): void {
    const navigation_link = environment.internalSpecimenResultLink + "highlights/";
    this.utility.navigate_to(navigation_link, this.nba_search_term);
  }

  /**
   * Loads all results using the saved query and the new amount of hits requested
   * @param category for which to load the results
   * @param amount of hits we want to retrieve
   * @author Luuk
   */
  protected load_all_multimedia(): void {
    const navigation_link = environment.internalMultimediaResultLink + "highlights/";
    this.utility.navigate_to(navigation_link, this.nba_search_term);
  }
}

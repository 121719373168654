/**
 * This component handles the visualisation of a single multimedia item
 */

// Library imports
import { Component, OnInit, Input, Output, HostListener, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";
import { environment } from "@src/environments/environment";
import { Meta, Title } from "@angular/platform-browser";

// Service imports
import { NbaService } from "@bioportal/services/api/nba.service";
import { HelperService } from "@bioportal/services/helper.service";
import { UtilityService } from "@bioportal/services/utility.service";
import { TranslateService } from "@ngx-translate/core";

// Model imports
import { Multimedia } from "@bioportal/models/Multimedia";
import { Search_data } from "@bioportal/models/Search_data";

@Component({
  selector: "app-multimedia-item",
  templateUrl: "./multimedia-item.component.html",
  styleUrls: ["./multimedia-item.component.scss"],
})
export class MultimediaItemComponent implements OnInit {
  // Listen for arrow keystrokes
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == ",") {
      this.browse_images("left");
    } else if (event.key == ".") {
      this.browse_images("right");
    }
  }

  @Input() search_data: Search_data;
  @Output() page_not_found = new EventEmitter<void>();

  id: string;
  dialog: boolean;
  title: string;
  isFound: boolean;

  current_multimedia: Multimedia;

  data: any = {};
  picture_collection: any;

  constructor(
    private location: Location,
    private meta: Meta,
    private title_service: Title,
    protected helper: HelperService,
    protected nba: NbaService,
    protected utility: UtilityService,
    protected translate: TranslateService,
  ) {}

  ngOnInit() {
    //this.isFound = false;
    this.current_multimedia = new Multimedia();
    this.id = this.search_data.endpoint;
    this.search_data.page_type = "multimedia-item";
    const query_spec = this.nba.convert_search_data_to_query_spec(this.search_data, environment.multimediaDenominator);
    this.request_multimedia(query_spec);
  }

  /**
   * Sends the given query spec to the NBA and processes incoming data
   * @param query_spec (object)
   * @author Luuk
   */
  private request_multimedia(query_spec: any): void {
    this.nba.get_data(query_spec, environment.multimediaDenominator).subscribe((data) => {
      if (data.totalSize > 0) {
        this.isFound = true;
        this.data = data.resultSet[0].item;
        this.data["specimen_id"] = this.data.sourceSystemId.split(["_"])[0];
        this.current_multimedia.set(this.data);
        // Check if taxa entries exists in the nba
        this.current_multimedia.identifications.forEach((element) =>
          this.nba.request_taxon_existence(element.scientificName.scientificNameGroup),
        );
        this.location.replaceState(`${this.translate.currentLang}/multimedia/${this.id}`);
        this.title = "Multimedia item - " + this.current_multimedia.multimedia_id;
        this.update_meta_tags();
      } else {
        this.isFound = false;
        this.page_not_found.emit();
      }
    });
  }

  private update_meta_tags() {
    const title = this.title + " | " + environment.appTitle;

    this.title_service.setTitle(title);
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });

    const content =
      "Image of specimen '" +
      this.current_multimedia.registration_number() +
      "' in the Naturalis Biodiversity Center collection.";

    this.meta.updateTag({
      name: "description",
      content: this.utility.strip_html_tags(content),
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.utility.strip_html_tags(content),
    });
    this.meta.updateTag({ property: "og:image", content: this.current_multimedia.medium_image() });

    const location = new URL(this.location.path(), environment.baseUrl);
    this.meta.updateTag({ name: "url", content: location.toString() });
    this.meta.updateTag({ property: "og:url", content: location.toString() });
  }

  /**
   * This function allows the user to browse through the loaded pictures, given the direction.
   * When the end of the loaded pictures array has been reached, the function will loop to the beginning
   * of the array again
   * @param direction string, denoting whether we are scrolling left or right
   * @author Luuk
   */
  protected browse_images(direction: string): void {
    this.current_multimedia.loading = false;
    let index = this.helper.multimedia_list.map((e: any) => e.sourceSystemId).indexOf(this.id);
    if (direction == "left") {
      index = index > 1 ? index - 1 : this.helper.multimedia_list.length - 1;
    } else {
      index = index < this.helper.multimedia_list.length - 1 ? index + 1 : 0;
    }
    this.current_multimedia = this.helper.multimedia_list[index];
    // Check if taxa entries exists in the nba
    this.current_multimedia.identifications.forEach((element) =>
      this.nba.request_taxon_existence(element.scientificName.scientificNameGroup),
    );
    this.current_multimedia.startLoading();
    this.id = this.current_multimedia.multimedia_id;
    this.location.replaceState(`${this.translate.currentLang}/multimedia/${this.id}`);
    this.title = this.dialog
      ? this.current_multimedia.multimedia_id
      : "Multimedia item - " + this.current_multimedia.multimedia_id;
    this.update_meta_tags();
  }
}

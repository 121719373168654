<div class="pt-2" *ngIf="this.isFound">
  <h1>{{ this.content.title }}</h1>
  <div id="content" [innerHTML]="this.content.body"></div>
</div>
<div *ngIf="this.is_downloads_page()">
  <ng-container *ngIf="this.dwca_specimens.length > 0">
    <h3>Specimens</h3>
    <ul class="dwca">
      <li *ngFor="let item of this.dwca_specimens">
        <a id="download-list-item" href="https://api.biodiversitydata.nl/v2/specimen/dwca/getDataSet/{{ item }}">{{
          item.replaceAll("-", " ")
        }}</a>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="this.dwca_taxa.length > 0">
    <h3>Taxa</h3>
    <ul class="dwca">
      <li *ngFor="let item of this.dwca_taxa">
        <a href="https://api.biodiversitydata.nl/v2/taxon/dwca/getDataSet/{{ item }}">{{ item.replace("-", " ") }}</a>
      </li>
    </ul>
  </ng-container>
</div>

<!-- Footer -->
<footer class="bottom-bar bg-dark container-fluid" id="footer">
  <div class="container">
    <div class="row">
      <div class="col-5 logo-container">
        <a href="https://naturalis.nl/{{ this.translate.currentLang }}" target="_new">
          <img src="/assets/img/logo-large-white.png" alt="Naturalis Biodiversity Center" class="img-fluid" />
        </a>
      </div>
      <div class="col-7 text-sm-end sitemap-links">
        <ul *ngIf="this.footer_menu">
          <ng-container *ngFor="let item of this.footer_menu">
            <li>
              <a
                href="{{ item.url }}"
                (click)="this.utility.click_prevented_navigate_to(item.url, $event)"
                routerLink="{{ item.url }}"
                >{{ item.title }}</a
              >
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</footer>

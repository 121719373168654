export const environment = {
  appTitle: "Naturalis Bioportal",
  defaultLanguage: "nl",
  languages: ["nl", "en"],
  defaultSearchOperator: "AND",
  dialogHeight: "95%",
  dialogWidth: "90%",
  dialogMaxHeight: "90vh",
  dialogMaxWidth: "1200px",
  googleApiKey: "AIzaSyCv168azSiPAf0wjVe36gNqdANDqEXkpvQ",
  googleTagManagerId: "GTM-PRGW6LT",
  dockerDrupalWebserver: "https://bioportal.naturalis.nl",
  baseUrl: "https://bioportal.naturalis.nl",
  defaultOperator: "OR",
  specimensDenominator: "specimens",
  multimediaDenominator: "multimedia",
  geoDenominator: "geo",
  taxaDenominator: "taxa",
  defaultQuerySize: 10,
  maxSpecimenResults: 50000,
  maxTaxonResults: 50000,
  maxNbaResults: 50000,
  maxGeoResults: 10000,
  multimediaItemsPerPage: 48,
  multimediaSearchListSize: 12,
  paginatorOptions: [25, 100, 1000],
  tableColumnsSpecimens: ["specimen", "species", "found_in"],
  tableColumnsTaxa: ["name", "description", "found_in"],
  databases: ["specimens", "taxa", "multimedia"],
  drop_downs: ["type_status", "type_material", "license"],
  licenseSearchValues: ["1.0", "2.0", "2.5", "3.0", "4.0"],
  nbaApiUrl: "https://api.biodiversitydata.nl/v2/",
  internalMultimediaResultLink: "/result/multimedia/",
  internalSpecimenResultLink: "/result/specimen/",
  internalTaxaResultLink: "/result/taxa",
  production: true,
  debuggingMode: false,
  table_filter_cutoff: 10,
};

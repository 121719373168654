<!-- Navigation bar -->
<div class="bg-black">
  <mat-toolbar color="primary" class="container-fluid navbar-menu" id="toolbar">
    <mat-icon class="navbar-icon button-margin" (click)="this.navigator.navigate('')" style="cursor: pointer">
      <img src="/assets/img/logo--white.png" alt="Naturalis Bioportal" title="Naturalis Bioportal" />
    </mat-icon>
    <a class="navbar-brand" (click)="this.navigator.navigate('')" style="cursor: pointer" title="Naturalis Bioportal">
      {{ this.title }}
    </a>

    <span class="spacer"></span>

    <ng-container *ngIf="this.menus && this.menus.has('highlight')">
      <button
        id="highlights_button"
        type="button"
        class="desktop-only btn navbar-menu button-margin"
        [matMenuTriggerFor]="highlightmenu">
        <mat-icon fontIcon="arrow_drop_down"></mat-icon>
        {{ "navbar.explore_highlights" | translate }}
      </button>
    </ng-container>

    <ng-container *ngIf="this.menus && this.menus.has('about')">
      <button type="button" class="desktop-only btn navbar-menu button-margin" [matMenuTriggerFor]="aboutmenu">
        <mat-icon fontIcon="arrow_drop_down"></mat-icon>
        {{ "navbar.about" | translate }}
      </button>
    </ng-container>

    <ng-container>
      <button
        type="button"
        class="desktop-only btn navbar-menu button-margin"
        [matMenuTriggerFor]="langMenu"
        id="langMenuButton">
        <mat-icon fontIcon="arrow_drop_down"></mat-icon>
        {{ this.translate.currentLang.toUpperCase() }}
      </button>
    </ng-container>

    <ng-container>
      <mat-icon
        class="navbar-icon button-margin"
        (click)="this.utility.click_prevented_navigate_to('/' + this.translate.currentLang + '/help', $event)"
        style="cursor: pointer"
        fontIcon="help">
      </mat-icon>
    </ng-container>

    <ng-container>
      <button type="button" class="mobile-only btn navbar-menu button-margin" [matMenuTriggerFor]="hamburgerMenu">
        <mat-icon fontIcon="menu"></mat-icon>
      </button>
    </ng-container>
  </mat-toolbar>
</div>

<!-- HamburgerMenu for mobile devices -->
<mat-menu #hamburgerMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="highlightmenu">
    {{ "navbar.explore_highlights" | translate }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="aboutmenu">
    {{ "navbar.about" | translate }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="langMenu">
    {{ this.translate.currentLang.toUpperCase() }}
  </button>
</mat-menu>

<!-- Navigation bar about menu -->
<mat-menu #aboutmenu="matMenu">
  <ng-container *ngIf="this.menus && this.menus.has('about')">
    <ng-container *ngFor="let item of this.menus.get('about')">
      <a href="{{ item.url }}" (click)="this.utility.click_prevented_navigate_to(item.url, $event)" mat-menu-item>
        {{ item.title }}
      </a>
    </ng-container>
  </ng-container>
</mat-menu>

<!-- Navigation bar highlight menu -->
<mat-menu #highlightmenu="matMenu">
  <ng-container *ngIf="this.menus && this.menus.has('highlight')">
    <ng-container *ngFor="let item of this.menus.get('highlight')">
      <a href="{{ item.url }}" (click)="this.utility.click_prevented_navigate_to(item.url, $event)" mat-menu-item>
        {{ item.title }}
      </a>
    </ng-container>
  </ng-container>
</mat-menu>

<mat-menu #langMenu="matMenu" id="langMenu">
  <a aria-label="English" (click)="this.change_language('en')" mat-menu-item id="langEN">EN</a>
  <a aria-label="Dutch" (click)="this.change_language('nl')" mat-menu-item id="langNL">NL</a>
</mat-menu>

<main class="container pb-sm-8 pb-2" role="main">
  <!--Bioportal shows components-->
  <app-results *ngIf="this.show.results_component" [search_data]="this.search_data"> </app-results>

  <app-search *ngIf="this.show.search_component"></app-search>

  <app-content
    *ngIf="this.show.content_component"
    [is_server]="this.is_server"
    (page_not_found)="this.show_page_not_found()">
  </app-content>

  <app-dashboard *ngIf="this.show.dashboard_component"></app-dashboard>

  <app-highlights
    *ngIf="this.show.highlights_component"
    [is_server]="this.is_server"
    [search_data]="this.search_data"
    (page_not_found)="this.show_page_not_found()">
  </app-highlights>

  <app-taxon-item
    *ngIf="this.show.taxon_item_component"
    [is_server]="this.is_server"
    [search_data]="this.search_data"
    (page_not_found)="this.show_page_not_found()">
  </app-taxon-item>

  <app-specimen-item
    *ngIf="this.show.specimen_item_component"
    [is_server]="this.is_server"
    [search_data]="this.search_data"
    (page_not_found)="this.show_page_not_found()">
  </app-specimen-item>

  <app-multimedia-item
    *ngIf="this.show.multimedia_item_component"
    [search_data]="this.search_data"
    (page_not_found)="this.show_page_not_found()">
  </app-multimedia-item>

  <app-not-found *ngIf="this.show.not_found"> </app-not-found>
</main>

<app-footer></app-footer>

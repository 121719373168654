/**
 * This component handles the Bioportal. It interprets the route parameters and shows components accordingly.
 */

import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformServer, Location } from "@angular/common";
import { ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "@src/environments/environment";
import { GoogleTagManagerService } from "angular-google-tag-manager";

// Service imports
import { NavigatorService } from "@bioportal/services/navigator.service";
import { UrlParserService } from "@bioportal/services/url-parser.service";
import { UtilityService } from "@bioportal/services/utility.service";
import { DrupalService } from "@bioportal/services/api/drupal.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "@bioportal/services/local-storage.service";

// Model imports
import { Search_data } from "@bioportal/models/Search_data";

export interface Show {
  content_component: boolean;
  dashboard_component: boolean;
  not_found: boolean;
  highlights_component: boolean;
  multimedia_item_component: boolean;
  results_component: boolean;
  search_component: boolean;
  specimen_item_component: boolean;
  taxon_item_component: boolean;
}

@Component({
  selector: "app-bioportal",
  templateUrl: "./bioportal.component.html",
  styleUrls: ["./bioportal.component.scss"],
})
export class BioportalComponent implements OnInit, OnDestroy {
  protected is_server: boolean;

  protected show: Show;
  protected search_data: Search_data;

  protected title = environment.appTitle;
  protected menus: Map<string, any>;

  private route_subscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private google_tag_manager: GoogleTagManagerService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private url_parser: UrlParserService,
    protected navigator: NavigatorService,
    protected utility: UtilityService,

    // private location: Location,
    private localStore: LocalStorageService,
    protected translate: TranslateService,
    protected drupal: DrupalService,
  ) {
    // For SSR, check if we are currently rendering on the server or on the client
    this.is_server = isPlatformServer(this.platformId);
  }

  ngOnInit() {
    // Set a default language as a fallback
    this.translate.use(environment.defaultLanguage);

    // Initialise the interface that controls which pages we show
    this.show = {
      dashboard_component: false,
      content_component: false,
      not_found: false,
      highlights_component: false,
      multimedia_item_component: false,
      results_component: false,
      search_component: false,
      specimen_item_component: false,
      taxon_item_component: false,
    };
    // On init, we parse our route parameters. We also take a route subscription. Now
    // everytime the route changes, we parse the parameters again and select pages accordingly.
    this.parse_legacy_url(this.route.snapshot);
    this.parse_parameters(this.route.snapshot);
    this.route_subscription = this.create_route_subscription();

    this.get_menus();
  }

  ngOnDestroy() {
    this.route_subscription.unsubscribe();
  }

  /**
   * Parses the given route parameters. Will modify the frontend accordingly.
   * @param params (Params) route parameters that will be interpreted.
   * @author Luuk
   */
  private parse_parameters(route_snapshot: ActivatedRouteSnapshot) {
    const params = route_snapshot.params;

    // The search data object is passed to child components
    this.search_data = this.url_parser.parse(route_snapshot);

    this.show = this.utility.set_object_booleans(this.show, false);
    // If no parameters are provided, or only the language, we go to the search component
    if (this.utility.is_empty_object(route_snapshot.params) || !("page" in params)) {
      this.translate.use(environment.defaultLanguage);
      this.show.search_component = true;
    }

    if ("language" in params) {
      const language = params["language"];
      if (environment.languages.includes(language)) {
        this.translate.use(language);
      } else {
        this.translate.use(environment.defaultLanguage);
      }
    }

    if ("page" in params) {
      const page = params["page"];
      if (page == "highlights") {
        this.show.highlights_component = true;
      } else if (page == "result") {
        this.show.results_component = true;
      } else if (page == "specimen") {
        this.show.specimen_item_component = true;
      } else if (page == "multimedia") {
        this.show.multimedia_item_component = true;
      } else if (page == "taxon") {
        this.show.taxon_item_component = true;
      } else if (page == "dashboard") {
        this.show.dashboard_component = true;
      } else {
        this.show.content_component = true;
      }
    }
  }

  /**
   * Shows 404 page.
   * @author Luuk
   */
  protected show_page_not_found() {
    this.show = this.utility.set_object_booleans(this.show, false);
    this.show.not_found = true;
  }

  /**
   * This function checks if the given URL is a legacy url. It will convert legacy
   * URLs into angular-bioportal URLs and navigate to this URL.
   * @param route_snapshot (ActivatedRoute)
   * @author Luuk
   */
  private parse_legacy_url(route_snapshot: ActivatedRouteSnapshot): void {
    const params = route_snapshot.params;
    // We support legacy URLs. These do not start with a language parameter.
    // We therefore check if the first parameter is a language: if not, we
    // reroute the given legacy url to its updated version
    if (
      // Check if url parameters have been given
      route_snapshot.url[0] &&
      // And whether the first one is not a language parameter
      !environment.languages.includes(route_snapshot.url[0].path)
    ) {
      // Now we need to construct the new URL. This would be easy if the wild card
      // in Angular routing would work. But it does not. Maybe in the next version?
      if (route_snapshot.routeConfig) {
        let new_url = "";
        const page = route_snapshot.routeConfig.path;
        new_url = page ? page.split("/")[0] : "";
        // Get all parameters, except the deprecated parameters that are set to none
        Object.keys(params).forEach((key) => {
          if (key != "none") {
            new_url += `/${params[key]}`;
          }
        });
        this.navigator.navigate(new_url);
      }
    }
    // If no parameters have been provided, and therefore no language, we use the default
    // language and navigate to main again, but now with a language specified
    if (route_snapshot.url.length == 0) {
      this.translate.use(environment.defaultLanguage);
      this.navigator.navigate("");
    }
    // If no legacy parameter has been detected, we do not route and continue.
  }

  /**
   * Create a route subscription that parses parameters when navigation has finished
   * @author Luuk
   */
  private create_route_subscription(): Subscription {
    return this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.parse_parameters(this.route.snapshot);
        this.push_google_tag(event.url);
      }
    });
  }

  /**
   * Pushes the given url to the google tag manager
   * @param url (string)
   * @author Luuk
   */
  private push_google_tag(url: string): void {
    this.google_tag_manager.pushTag({
      event: "page",
      pageName: url,
    });
  }

  /**
   * Retrieves the menu's from Drupal.
   * @author Joep
   */
  private get_menus() {
    const menus = new Map<string, any>();

    for (const menuName of ["highlight", "about"]) {
      this.drupal.query_menu_content(menuName).subscribe({
        next: (result: any) => {
          const menuItems = [];
          for (const item of result.data) {
            item.attributes["url"] = item.attributes["url"].replace("#", "/");
            menuItems.push(item.attributes);
          }
          menus.set(menuName, menuItems);
        },
        error: (error: any) => {
          this.utility.show_error_message("errors.drupal"), console.debug(error);
        },
        complete: () => {
          this.menus = menus;
        },
      });
    }
  }

  /**
   * Changes the language parameter in the URL when the language is changed.
   * @param language (string)
   * @author Luuk
   */
  private change_language_in_url(language: string): void {
    const url = this.router.url;
    let new_url: string;
    // Allow the start page to be handled correctly. We cannot by default search for '/nl' and '/en', as
    // this will force 'https://bioportal.dryrun.link/nl/highlights/en_tibi' to be changed to
    // 'https://bioportal.dryrun.link/nl/highlights/nl_tibi' at startup.
    if (url.endsWith("/en") || url.endsWith("/nl")) {
      new_url = language == "en" ? url.replace("/nl", "/en") : url.replace("/en", "/nl");
    } else {
      new_url = language == "en" ? url.replace("/nl/", "/en/") : url.replace("/en/", "/nl/");
    }
    this.location.replaceState(new_url);
  }

  /**
   * Simple function to change the language of the program.
   * @param language string containing the abbreviation of the language
   * @author Luuk
   */
  protected change_language(language: string): void {
    this.translate.use(language);
    this.change_language_in_url(language);
    this.localStore.saveData("language", language);
    this.get_menus();
  }

  /**
   * Returns the language based on the route, localstorage or default language
   * @param route_parameter with language. Will be checked for validity
   * @author Luuk
   */
  private get_language(route_language?: string): string {
    if (!this.is_server) {
      // This function can only be run when we are running client sided, as localStore is a local browser functionality
      if (this.show.specimen_item_component) {
        // pUrl entry, we return English if local storage is empty
        return this.localStore.has("language") ? this.localStore.getData("language") : "en";
      }
      if (route_language) {
        if (environment.languages.includes(route_language)) {
          return route_language;
        }
      }
      if (this.localStore.has("language")) {
        return this.localStore.getData("language");
      }
      return environment.defaultLanguage;
    } else {
      return environment.defaultLanguage;
    }
  }
}

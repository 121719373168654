import { Component, Input, ViewChild, AfterViewInit, OnDestroy, OnChanges } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { environment } from "@src/environments/environment";

// Service imports
import { HelperService } from "@bioportal/services/helper.service";
import { NavigatorService } from "@bioportal/services/navigator.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { SearchFormService } from "@bioportal/services/search/search-form.service";
import { UtilityService } from "@bioportal/services/utility.service";

// Model imports
import { Specimen } from "@bioportal/models/Specimen";

@Component({
  selector: "app-specimen-table",
  templateUrl: "./specimen-table.component.html",
  styleUrls: ["./specimen-table.component.scss"],
})
export class SpecimenTableComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("sort") sort: MatSort;

  //TODO: specify what type of data the table expects
  @Input() specimen_data: any;

  protected table_columns: string[] = environment.tableColumnsSpecimens;
  protected table_data: MatTableDataSource<object>;
  private table_source_data: object[];

  protected show_filter: boolean;
  protected paginator_options: number[] = environment.paginatorOptions;

  constructor(
    protected navigator: NavigatorService,
    protected search_form: SearchFormService,
    protected utility: UtilityService,
    protected helper: HelperService,
    public nba: NbaService,
  ) {}

  ngOnChanges() {
    // When we receive new specimen_data, we recreate our table data with the received data.
    this.table_data = new MatTableDataSource(this.create_table_data(this.specimen_data.result_set));
    this.show_filter = this.specimen_data.result_set.length > environment.table_filter_cutoff;
  }

  ngAfterViewInit() {
    this.table_data.paginator = this.paginator;
    this.table_data.sort = this.sort;

    if (this.helper.table_exists(environment.specimensDenominator)) {
      this.table_data.paginator.pageIndex = this.helper.get_table_page_index(environment.specimensDenominator);
      this.table_data.paginator.pageSize = this.helper.get_table_page_size(environment.specimensDenominator);
      this.table_data.filter = this.helper.get_table_filter(environment.specimensDenominator);
      this.table_data.sort.active = this.helper.get_table_sort_column(environment.specimensDenominator);
      this.table_data.sort.direction = this.helper.get_table_sort_direction(environment.specimensDenominator);
    }
  }

  ngOnDestroy() {
    // On destruction, we will save the previous specimen list (including sorting)
    // for possible browsing of specimens.
    if (this.table_data.sort) {
      this.helper.specimen_list = [];
      // Sort the specimen list in the helper as it is sorted in the table to allow
      // proper browsing in the specimen-item component.
      this.table_data
        .sortData(this.table_data.filteredData, this.table_data.sort)
        .forEach((element: any) => this.helper.specimen_list.push(element.full_data));
    }
    this.helper.save_table_data(environment.specimensDenominator, this.table_data);
  }

  /**
   * Takes a list of specimen items and turns it into table data
   * @param specimen_list Specimen[] received from the API
   * @return list with objects readable by the specimen table
   * @author Luuk
   */
  private create_table_data(specimen_list: Specimen[]): object[] {
    const table_list: object[] = [];

    specimen_list.forEach((specimen: Specimen) => {
      const table_object: any = {
        specimen: specimen.registration_number,
        found_in: specimen.source_system_name,
        species: [],
        full_data: specimen,
      };
      // Provide the multiple species identifications in a list as the material table requires
      if (specimen.identifications.length) {
        table_object["species"].push(specimen.identifications[0].scientificName.fullScientificName);
      } else {
        table_object["species"].push("");
      }
      table_list.push(table_object);
    });
    return table_list;
  }

  /**
   * Applies the sorting and filtering of the given table. Handles pagination if necessary.
   * @param event handles the filtering if provided
   * @param table that needs to be sorted/filtered
   * @author Luuk
   */
  public apply_sort_filter(event: Event, table: MatTableDataSource<object>): void {
    const filterValue = (event.target as HTMLInputElement).value;
    table.filter = filterValue.toLowerCase();

    if (table.paginator) {
      table.paginator.firstPage();
    }
  }
}

/**
 * This service helps with parsing the URL. Components like the MultimediaList can provide this
 * service with the URL parameters. The service will provide an object with all data necessary
 * to create a query_spec to retrieve data from the NBA.
 * @author Luuk
 */

// Library imports
import { Injectable } from "@angular/core";
import { Router, Params, ActivatedRouteSnapshot } from "@angular/router";

// Service imports
import { HelperService } from "./helper.service";
import { UtilityService } from "./utility.service";

// Model imports
import { Search_data } from "@bioportal/models/Search_data";

import { environment } from "@src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UrlParserService {
  constructor(
    private helper: HelperService,
    private router: Router,
    private utility: UtilityService,
  ) {}

  /**
   * Process the URL parameters by turning them into a search_data object. This object is used to create
   * a query_spec in order to communicate with the NBA
   * @param route_snapshot (ActivatedRouteSnapshot)
   * @returns Search_data
   * @author Luuk
   */
  public parse(route_snapshot: ActivatedRouteSnapshot): Search_data {
    const search_data = new Search_data();

    const params = route_snapshot.params;
    let query_params = route_snapshot.queryParams;

    search_data.params = params;
    search_data.query_params = query_params;

    search_data.url = this.router.url;
    search_data.save_identifier = this.get_save_identifier(search_data.url);

    search_data.page_type = params["page_type"] ? params["page_type"] : "url_query";
    search_data.search_term = params["id"] ? params["id"] : "";
    search_data.endpoint = params["endpoint"] ? params["endpoint"] : "";

    search_data.size = environment.defaultQuerySize;

    if ("s_scientificName" in query_params) {
      query_params = this.process_legacy_url(query_params);
    }

    if (query_params["polygon"]) {
      search_data.geosearch = true;
      if (typeof query_params["polygon"] == "string") {
        search_data.polygon = this.resolve_polygon_string(query_params["polygon"]);
      } else {
        search_data.polygon = this.resolve_polygon(query_params["polygon"]);
      }
    }

    if (query_params["area"]) {
      search_data.geosearch = true;
      search_data.area = query_params["area"];
      // Find name corresponding to the area. TODO: needs to be in env.
      const categories = ["countries", "municipalities", "nature"];

      // Make sure that we have geo data before handling these requests
      // FIXME: make sure the application always posesses geo data before running this function
      if (!this.utility.is_empty_object(this.helper.geo_data)) {
        for (const i in categories) {
          const res = this.helper.geo_data[categories[i]].find((i: any) => i.id === search_data.area);
          if (res) {
            this.helper.selected_area = res;
            break;
          }
        }
      } else {
        this.helper.selected_area = "";
      }
    }

    for (const field in search_data.search_fields) {
      if (query_params[field]) {
        search_data.search_fields[field] = query_params[field];
      }
    }

    // Set the operator after all query_params have been processed
    search_data.operator = query_params["operator"] ? query_params["operator"] : environment.defaultOperator;

    // Format search fields based on the presence of the basic term. If it has been used, we will
    // add its value to all search fields. If no basic term is present, we will prune unused fields
    search_data.using_basic_term = search_data.search_fields.basic_term == "" ? false : true;
    if (search_data.using_basic_term) {
      search_data.search_fields = this.search_basic_term(search_data.search_fields);
    } else {
      search_data.search_fields = this.utility.remove_empty_fields_from_object(search_data.search_fields);
    }

    search_data.search_fields = this.put_strings_inside_array(search_data.search_fields);

    return search_data;
  }

  /**
   * If the basic term is used, we will fill all field values with this field data
   * to be able to search the entire NBA on that term
   * @param obj (object) with the basic term provided
   * @returns object with basic term in all its fields
   * @author Luuk
   */
  public search_basic_term(obj: any): object {
    const basic_term: string = obj["basic_term"];
    delete obj["basic_term"];
    for (const item in obj) {
      obj[item] = basic_term;
    }
    return obj;
  }

  /**
   * Form entries are strings. This function takes the form values and puts them
   * into arrays. (useful for splitting on whitespace with nba improvements)
   * TODO: needs refactor with the query builder refactor
   * @param form_values
   * @returns form_values with their strings replaced by lists of strings
   * @author Luuk
   */
  private put_strings_inside_array(form_values: object): object {
    const form: any = structuredClone(form_values);
    for (const field in form) {
      // We only want to split strings on whitespace. Leave arrays be
      if (this.utility.isString(form[field])) {
        if (form[field]) {
          form[field] = [form[field].replaceAll("_", " ")];
        }
      }
    }
    return form;
  }

  /**
   * Retrieves the save identifier from the url.
   * @param url (string)
   * @return string
   */
  private get_save_identifier(url: string): string {
    url = url.replace("/en/", "");
    url = url.replace("/nl/", "");
    return url;
  }

  /**
   * Converts the polygon provided to the URL to a usable nba polygon
   * @param polygon
   * @author Luuk
   */
  public resolve_polygon_string(raw_polygon: string): any {
    const resolved_polygon: any[] = [];

    let odd_string = "";
    let counter = 0;
    const split_string = raw_polygon.split(",");

    // Now we need to put the lng,lat per pair in a list
    for (const i in split_string) {
      counter += 1;
      if (counter % 2 == 0) {
        resolved_polygon.push([parseFloat(odd_string), parseFloat(split_string[i])]);
        odd_string = "";
      }
      odd_string = split_string[i];
    }
    return resolved_polygon;
  }

  public resolve_polygon(polygon: any[]): any {
    const resolved_polygon: any[] = [];
    for (const i in polygon) {
      const coordinates = polygon[i].split(",");
      resolved_polygon.push([coordinates[0], coordinates[1]]);
    }
    return resolved_polygon;
  }

  /**
   * If a legacy URL has been detected, we need to convert the old query params to the new ones.
   * @param params object with legacy query parameters
   * @returns object with current query parameters
   */
  private process_legacy_url(params: Params): Params {
    const resolved_params: any = {
      type_status: [],
      type_material: [],
      license: [],
      source: [],
    };
    let operator = environment.defaultOperator;

    for (const key in params) {
      const value = params[key];

      if (value) {
        if (key.includes("scientificName")) {
          resolved_params["scientific_name"] = value;
        } else if (key.includes("term")) {
          resolved_params["basic_term"] = value;
        } else if (key.includes("vernacularName")) {
          resolved_params["common_name"] = value;
        } else if (key.includes("genusOrMonomial")) {
          resolved_params["genus"] = value;
        } else if (key.includes("specificEpithet")) {
          resolved_params["epithet"] = value;
        } else if (key.includes("unitID")) {
          resolved_params["registration_number"] = value;
        } else if (key.includes("sourceSystem")) {
          resolved_params["source"] = value;
        } else if (key.includes("collectionType")) {
          resolved_params["collection_name"].push(value);
        } else if (key.includes("localityText")) {
          resolved_params["locality"] = value;
        } else if (key.includes("phaseOrStage")) {
          resolved_params["phase_stage"] = value;
        } else if (key.includes("sex")) {
          resolved_params["sex"] = value;
        } else if (key.includes("gatheringAgent")) {
          resolved_params["collector"] = value;
        } else if (key.includes("collectorsFieldNumber")) {
          resolved_params["collector_field_number"] = value;
        } else if (key.includes("kingdom")) {
          resolved_params["kingdom"] = value;
        } else if (key.includes("phylum")) {
          resolved_params["phylum"] = value;
        } else if (key.includes("className")) {
          resolved_params["class"] = value;
        } else if (key.includes("order")) {
          resolved_params["order"] = value;
        } else if (key.includes("infraspecificEpithet")) {
          resolved_params["infraspecific_name"] = value;
        } else if (key.includes("previousUnitsText")) {
          resolved_params["old_barcodes"] = value;
        } else if (key.includes("chronoStratigraphy")) {
          resolved_params["chronostratigraphy"] = value;
        } else if (key.includes("lithoStratigraphy")) {
          resolved_params["lithostratigraphy"] = value;
        } else if (key.includes("bioStratigraphy")) {
          resolved_params["biostratigraphy"] = value;
        } else if (key.includes("typeStatus")) {
          resolved_params["type_status"].push(value);
        } else if (key.includes("kindOfUnit")) {
          resolved_params["type_material"] = value;
        } else if (key.includes("subgenus")) {
          resolved_params["subgenus"] = value;
        } else if (key.includes("license")) {
          resolved_params["license"].push(value);
        } else if (key.includes("language")) {
          resolved_params["language"] = value;
        } else if (key.includes("andOr")) {
          operator = value == 1 ? "OR" : "AND";
        } else if (key.includes("gid")) {
          const gid = value.split("_");
          resolved_params["area"] = `${gid[gid.length - 1]}@GEO`;
        }
      }
    }

    // Delete the created lists if they return empty
    if (!resolved_params.type_status?.length) {
      delete resolved_params.type_status;
    }
    if (!resolved_params.type_material?.length) {
      delete resolved_params.type_material;
    }
    if (!resolved_params.license?.length) {
      delete resolved_params.license;
    }
    if (!resolved_params.source?.length) {
      delete resolved_params.source;
    }

    resolved_params["operator"] = operator;
    return resolved_params;
  }
}

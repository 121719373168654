<div style="padding-top: 1em; height: fit-content">
  <mat-form-field class="table-filter-form-field" [hidden]="!this.show_filter">
    <mat-label>{{ "table.filter_taxa" | translate }}</mat-label>
    <input
      matInput
      (keyup)="this.apply_sort_filter($event, this.table_data)"
      [(ngModel)]="this.table_data.filter"
      placeholder="Ex. pardus"
      #input />
  </mat-form-field>

  <div class="mat-elevation-z1">
    <table mat-table [dataSource]="this.table_data" matSort #sort="matSort" id="cy_taxa_table">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-4">{{ "table.name" | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <a class="a-no-redirect">{{ row.name }}</a>
          <div *ngIf="row.popular_name" class="popular-name">
            {{ row.popular_name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description"
        ><th mat-header-cell *matHeaderCellDef mat-sort-header class="col-5">{{ "table.description" | translate }}</th>

        <td mat-cell *matCellDef="let row">
          <p class="max-three-lines">
            {{ row.description }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="found_in" class="col-3">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "table.found_in" | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.found_in }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="this.table_columns"></tr>
      <tr
        (click)="this.utility.navigate_to('taxon', row.full_data.item.acceptedName.scientificNameGroup)"
        mat-row
        class="highlight-row"
        *matRowDef="let row; columns: this.table_columns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">{{ "table.no_filter_data" | translate }} "{{ input.value }}"</td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="this.paginator_options"
      [hidePageSize]="!this.show_filter"
      #paginator="matPaginator"
      aria-label="Select page of taxa">
    </mat-paginator>
  </div>
</div>

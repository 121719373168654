/**
 * This service handles storing and retrieving data
 */
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public storage: any = {
    multimedia: [],
    specimens: [],
    taxa: [],
    geo: [],
    assemblage: [],
  };

  //constructor() {}

  /**
   * Gets the data from the given endpoint and identifier.
   * @param endpoint (string)
   * @param identifier (string)
   * @author Luuk
   */
  public get(endpoint: string, identifier: string): any {
    if (endpoint in this.storage) {
      const target = this.storage[endpoint].findIndex((obj: any) => obj.identifier === identifier);
      if (target != -1) {
        return this.storage[endpoint][target].data;
      } else {
        return {};
      }
    } else {
      console.error("No such endpoint");
    }
  }
  /**
   * Saves the given data from the given endpoint. Identified by the query parameters from the URL.
   * @param endpoint (string)
   * @param data (object)
   * @param identifier (string)
   * @author Luuk
   */
  public save(endpoint: string, data: any, identifier: string): void {
    if (endpoint in this.storage) {
      const target = this.storage[endpoint].findIndex((obj: any) => obj.identifier === identifier);
      if (target != -1) {
        this.storage[endpoint].splice(target, 1);
      }
      this.storage[endpoint].push({
        identifier: identifier,
        data: data,
      });
    } else {
      console.error("No such endpoint");
    }
  }

  /**
   * Checks whether data has been saved for the endpoint + identifier
   * @param endpoint (string)
   * @param identifier (string)
   * @return boolean
   * @author Luuk
   */
  public has(endpoint: string, identifier: string): boolean {
    return this.storage[endpoint].findIndex((obj: any) => obj.identifier === identifier) >= 0;
  }
}

import { Identification } from "@bioportal/models/Identification";
/**
 * Typescript object to handle all the incoming specimen JSON data
 */
export class Specimen {
  default_value = "—";

  identifications: Identification[] = [];

  vernaculars: any[] = [];

  identifier = "";
  scientific_name = "—";
  registration_number = "—";
  license = "—";
  institution = "—";
  collection_name = "—";

  source = "—";

  record_basis = "—";
  type_status = "—";
  phase_stage = "—";
  sex = "—";
  part = "—";
  preparation_method = "—";

  number_of_specimen = "—";
  collectors_field_number = "—";
  modified = "—";
  previous_units_text = "—";

  source_system_code = "—";
  source_system_name = "—";

  gathering_event_world_region = "—";
  gathering_event_continent = "—";
  gathering_event_country = "—";
  gathering_event_locality_text = "—";
  gathering_event_date_time_begin = "—";
  gathering_event_date_time_end = "—";
  gathering_event_gathering_persons: any[] = [];
  gathering_event_date = "—";

  gathering_event_site_coordinates: any[] = [];
  position: google.maps.LatLngLiteral;

  country = "";
  province_state = "";
  locality = "";
  formatted_locality = "";

  cite_as = "";
  assemblage_id = "";
  assemblage: any[] = [];

  constructor(specimen?: Partial<Specimen>) {
    // Allow the partial initialisation of a specimen object
    Object.assign(this, specimen);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param specimen with JSON data received from the server
   * @author Luuk
   */
  public set(specimen: any) {
    this.identifier = "id" in specimen ? specimen["id"] : "";
    this.set_identifications(specimen);

    this.cite_as = "unitGUID" in specimen ? specimen["unitGUID"] : "";

    this.assemblage_id = "assemblageID" in specimen ? specimen["assemblageID"] : "";

    if ("sourceSystemId" in specimen) {
      this.registration_number = specimen["sourceSystemId"];
    }
    if ("license" in specimen) {
      this.license = specimen["license"];
    }
    if ("owner" in specimen) {
      this.institution = specimen["owner"];
    }
    if ("collectionType" in specimen) {
      this.collection_name = specimen["collectionType"];
    }

    if ("recordBasis" in specimen) {
      this.record_basis = specimen["recordBasis"];
    }

    if (this.identifications.length) {
      // Check if we can find a type status in one of the identifications
      let type_status_list: string[] = [];
      this.identifications.forEach((identification: any) => {
        if ("typeStatus" in identification) {
          type_status_list.push(identification.typeStatus);
          // Remove all empty strings
          type_status_list = type_status_list.filter((e: any) => String(e).trim());
        }
      });
      this.type_status = type_status_list.length ? type_status_list[0] : "—";
    }

    if ("phaseOrStage" in specimen) {
      this.phase_stage = specimen["phaseOrStage"];
    }
    if ("sex" in specimen) {
      this.sex = specimen["sex"];
    }
    if ("kindOfUnit" in specimen) {
      this.part = specimen["kindOfUnit"];
    }
    if ("preparationType" in specimen) {
      this.preparation_method = specimen["preparationType"];
    }

    if ("numberOfSpecimen" in specimen) {
      this.number_of_specimen = specimen["numberOfSpecimen"];
    }
    if ("collectorsFieldNumber" in specimen) {
      this.collectors_field_number = specimen["collectorsFieldNumber"];
    }
    if ("modified" in specimen) {
      this.modified = specimen["modified"].split("T")[0];
    }
    if ("previous_units_text" in specimen) {
      this.previous_units_text = specimen["previous_units_text"];
    }

    if ("sourceSystem" in specimen) {
      this.source_system_code = specimen["sourceSystem"]["code"];
      this.source_system_name = specimen["sourceSystem"]["name"];
    }
    if ("gatheringEvent" in specimen) {
      // We add a space after each locality for easy formatting in the format_localities function
      if ("localityText" in specimen["gatheringEvent"]) {
        this.gathering_event_locality_text = specimen["gatheringEvent"]["localityText"] + " ";
      }
      if ("worldRegion" in specimen["gatheringEvent"]) {
        this.gathering_event_world_region = specimen["gatheringEvent"]["worldRegion"] + " ";
      }
      if ("continent" in specimen["gatheringEvent"]) {
        this.gathering_event_continent = specimen["gatheringEvent"]["continent"] + " ";
      }
      if ("country" in specimen["gatheringEvent"]) {
        this.gathering_event_country = specimen["gatheringEvent"]["country"] + " ";
      }
      if ("locality" in specimen["gatheringEvent"]) {
        this.locality = specimen["gatheringEvent"]["locality"] + " ";
      }
      if ("country" in specimen["gatheringEvent"]) {
        this.country = specimen["gatheringEvent"]["country"] + " ";
      }

      this.province_state =
        "provinceState" in specimen["gatheringEvent"] ? specimen["gatheringEvent"]["provinceState"] + " " : "";
      this.formatted_locality = this.format_localities();

      if ("dateTimeBegin" in specimen["gatheringEvent"]) {
        this.gathering_event_date_time_begin = specimen["gatheringEvent"]["dateTimeBegin"];
      }
      if ("dateTimeEnd" in specimen["gatheringEvent"]) {
        this.gathering_event_date_time_end = specimen["gatheringEvent"]["dateTimeEnd"];
      }
      if ("dateText" in specimen["gatheringEvent"]) {
        this.gathering_event_date = specimen["gatheringEvent"]["dateText"];
      }
      if ("gatheringPersons" in specimen["gatheringEvent"]) {
        this.gathering_event_gathering_persons = specimen["gatheringEvent"]["gatheringPersons"];
      }
      if ("siteCoordinates" in specimen["gatheringEvent"]) {
        this.gathering_event_site_coordinates = specimen["gatheringEvent"]["siteCoordinates"];
        const lat: any = specimen["gatheringEvent"]["siteCoordinates"][0].latitudeDecimal;
        const lng: any = specimen["gatheringEvent"]["siteCoordinates"][0].longitudeDecimal;

        // Get the position of the specimen to show on the map
        this.position = {
          lat: lat,
          lng: lng,
        };
      }
    }

    // Now handle the multipe date/time formats
    if (this.gathering_event_date_time_begin != "—" && this.gathering_event_date_time_end != "—") {
      if (this.gathering_event_date_time_begin == this.gathering_event_date_time_end) {
        this.gathering_event_date = this.gathering_event_date_time_begin.split("T")[0];
      } else {
        this.gathering_event_date =
          this.gathering_event_date_time_begin.split("T")[0] + "—" + this.gathering_event_date_time_end.split("T")[0];
      }
    }
  }

  /**
   * Returns true if the specimen has geo coordinates
   */
  public has_position(): boolean {
    return this.position == undefined ? false : true;
  }

  /**
   * Checks if the current specimen has vernaculars
   * @author Luuk
   */
  public has_vernaculars(): boolean {
    return this.identifications.some((identification) => identification.vernacularNames.length > 0);
  }

  /**
   * Formats all available localities into a string
   * @return string with formatted locality
   */
  private format_localities(): string {
    if (this.gathering_event_locality_text != "—") {
      return this.gathering_event_locality_text;
    } else {
      return `${this.gathering_event_continent}
              ${this.gathering_event_world_region}
              ${this.gathering_event_country}
              ${this.province_state}
              ${this.locality}`.replaceAll("—", "");
    }
  }

  /**
   * Sets the identifications, which is a list of nested objects.
   * @param specimen (any)
   * @author Luuk
   */
  private set_identifications(specimen: any) {
    if ("identifications" in specimen) {
      specimen["identifications"].forEach((identification: any) => {
        this.identifications.push(new Identification(identification));
      });
    }

    // Sort the array so that the object with preferred set to true comes first
    this.identifications.sort((a, b) => {
      // If a is preferred, it should come before b
      if (a.preferred && !b.preferred) {
        return -1;
      }
      // If b is preferred, it should come before a
      if (!a.preferred && b.preferred) {
        return 1;
      }
      // If both are preferred or both are not preferred, their order doesn't matter
      return 0;
    });
  }
}

// Library imports
import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

// Model imports
import { Content } from "@bioportal/models/Content";

// Service imports
import { DrupalService } from "@bioportal/services/api/drupal.service";
import { NbaService } from "@src/app/services/api/nba.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@src/app/services/utility.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit {
  @Input() is_server: boolean;
  @Output() page_not_found = new EventEmitter<void>();

  content: Content;
  url: string;
  isFound = false;
  public dwca_specimens: string[];
  public dwca_taxa: string[];

  constructor(
    private location: Location,
    private drupal: DrupalService,
    private nba: NbaService,
    private translate: TranslateService,
    private utility: UtilityService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // get path from the url and use it to get the content
    this.route.url.subscribe((url) => {
      this.url = "/" + url.join("/");
      this.load_content();
    });
    this.translate.onLangChange.subscribe({
      next: () => {
        this.url = this.location.path();
        this.load_content();
      },
    });
  }

  public is_downloads_page() {
    return this.url.endsWith("/downloads");
  }

  private load_content() {
    const pages = new Map<string, any>();
    this.drupal.query_page_content().subscribe((result) => {
      for (const i in result.data) {
        pages.set(result.data[i].attributes.path.alias, result.data[i].attributes);
      }
      if (pages.has(this.url)) {
        const page_content = pages.get(this.url);
        this.content = new Content();
        this.content.set(page_content);
        this.location.replaceState(this.url);
        this.utility.update_meta_tags(this.content.title);
        this.isFound = true;
        if (this.is_downloads_page()) {
          this.nba.get_dwca_sets("dwca_specimen_datasets").subscribe({
            next: (data) => {
              this.dwca_specimens = data;
            },
          });
          this.nba.get_dwca_sets("dwca_taxon_datasets").subscribe({
            next: (data) => {
              this.dwca_taxa = data;
            },
          });
        }
      }
      // If we cannot find the page, we show the not found page
      if (!this.isFound) {
        this.page_not_found.emit();
      }
    });
  }
}

import { Component, OnChanges, Input, Output, EventEmitter } from "@angular/core";
import { environment } from "@src/environments/environment";
import { HostListener } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

// Service imports
import { NbaService } from "@bioportal/services/api/nba.service";
import { HelperService } from "@bioportal/services/helper.service";
import { UtilityService } from "@bioportal/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigatorService } from "@bioportal/services/navigator.service";
import { SearchFormService } from "@bioportal/services/search/search-form.service";

// Model imports
import { Specimen } from "@bioportal/models/Specimen";
import { Location } from "@angular/common";
import { Search_data } from "@bioportal/models/Search_data";

@Component({
  selector: "app-specimen-item",
  templateUrl: "./specimen-item.component.html",
  styleUrls: ["./specimen-item.component.scss"],
})
export class SpecimenItemComponent implements OnChanges {
  // Listen for arrow keystrokes
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "," && this.allow_browse) {
      this.browse("left");
    } else if (event.key == "." && this.allow_browse) {
      this.browse("right");
    }
  }

  @Input() search_data: Search_data;
  @Input() is_server: boolean;
  @Output() page_not_found = new EventEmitter<void>();

  id: string;
  data: any;
  isFound: boolean;

  protected allow_browse = false;
  protected shown_index: number;

  current_specimen: Specimen;

  // Google Maps settings
  svg_marker: any;
  map_zoom = 0;
  map_options: any;
  map_marker_options: any;
  map_marker_positions: google.maps.LatLngLiteral[] = [];

  protected multimedia_data: any = [];

  constructor(
    private translate: TranslateService,
    private location: Location,
    protected nba: NbaService,
    private title_service: Title,
    private meta: Meta,
    protected helper: HelperService,
    protected navigator: NavigatorService,
    protected search_form: SearchFormService,
    protected utility: UtilityService,
  ) {
    if (!this.is_server) {
      // Google maps settings can only be initialized on the client side
      this.initGoogleMapsSettings();
    }
  }

  ngOnChanges() {
    // We will show the option to browse specimen on two conditions:
    // 1. Is there a specimen list available (a specimen list is added to the helper service when a table is destroyed)
    // 2. Did we just route from a page where a specimen table is shown
    this.allow_browse = this.helper.specimen_list.length > 1 && this.helper.show_specimen_item_browse();

    this.current_specimen = new Specimen();

    this.search_data.page_type = "specimen-item";
    const query_spec = this.nba.convert_search_data_to_query_spec(this.search_data, environment.specimensDenominator);
    this.request_specimen(query_spec);
  }

  /**
   * Sends the given query spec to the NBA and processes incoming data
   * @param query_spec (object)
   * @author Luuk
   */
  private request_specimen(query_spec: any): void {
    this.nba.get_data(query_spec, environment.specimensDenominator).subscribe((data) => {
      this.current_specimen = new Specimen();
      this.map_marker_positions = [];
      if (data.totalSize > 0) {
        this.current_specimen.set(data.resultSet[0].item);
        this.shown_index =
          this.helper.specimen_list.findIndex(
            (specimen: Specimen) => specimen.identifier === this.current_specimen.identifier,
          ) + 1;
        // Allow browsing if the current specimen is found in the specimen list
        this.allow_browse = this.shown_index != 0 && this.helper.show_specimen_item_browse();
        this.isFound = true;
        // Now retrieve the linked multimedia
        this.update_meta_tags();
        this.request_linked_multimedia();
        // And the linked assemblages
        this.request_linked_assemblage();
        // Add the specimen position to our marker list
        this.map_marker_positions.push(this.current_specimen.position);
        // Check if taxa entries exists in the nba
        this.current_specimen.identifications.forEach((element) => {
          this.nba.request_taxon_existence(element.scientificName.scientificNameGroup);
        });
      } else if (data.totalSize == 0) {
        this.isFound = false;
        this.page_not_found.emit();
      }
    });
  }

  /**
   * Retrieve the linked specimen for the current specimen
   * @author Luuk
   */
  private request_linked_assemblage(): void {
    if (this.current_specimen.assemblage_id != "") {
      const query_spec = this.nba.get_linked_assemblage_query_spec(this.current_specimen.assemblage_id);
      this.nba.get_data(query_spec, environment.specimensDenominator).subscribe((data) => {
        let assemblage = data.resultSet;
        // Delete current item from the retrieved list
        assemblage = assemblage.filter(
          (object: any) => object.item.sourceSystemId !== this.current_specimen.registration_number,
        );
        this.current_specimen.assemblage = assemblage;
      });
    }
  }

  /**
   * Requests the NBA for linked multimedia given the registration number of the specimen
   * @param registration_number (string)
   * @author Luuk
   */
  private request_linked_multimedia(): void {
    const query_spec = this.nba.get_linked_multimedia_query_spec(this.current_specimen.identifier);
    this.nba.get_data(query_spec, environment.multimediaDenominator).subscribe((data) => {
      this.multimedia_data = data;
    });
  }

  private initGoogleMapsSettings(): void {
    // Map options
    // Declare the marker we will put on the map
    this.svg_marker = {
      // https://developers.google.com/maps/documentation/javascript/symbols
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "red",
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 5,
    };
    // Provide a default zoom level
    this.map_zoom = 7;
    // Options for the interface of the map
    this.map_options = {
      zoomControl: true,
      mapTypeControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: "hybrid",
    };
    // Options for the markers
    this.map_marker_options = {
      draggable: false,
      icon: this.svg_marker,
    };
    // Marker position list
    this.map_marker_positions = [];
  }

  public update_meta_tags() {
    const title = "Specimen " + this.current_specimen.registration_number + " | " + environment.appTitle;

    this.title_service.setTitle(title);
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });

    const content =
      "Specimen '" + this.current_specimen.registration_number + "' in the Naturalis Biodiversity Center collection.";

    this.meta.updateTag({
      name: "description",
      content: this.utility.strip_html_tags(content),
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.utility.strip_html_tags(content),
    });

    const location = new URL(this.location.path(), environment.baseUrl);
    this.meta.updateTag({ name: "url", content: location.toString() });
    this.meta.updateTag({ property: "og:url", content: location.toString() });
  }

  /**
   * Helper function to retrieve an assemblage item. We route to this component again to
   * ensure that the backbutton will work.
   * @param specimen (string)
   * @author Luuk
   */
  protected request_assemblage_item(specimen: string) {
    // Scroll to the top of the window to properly show the change of page
    window.scroll(0, 0);
    this.navigator.navigate(`/specimen/${specimen}`);
  }

  /**
   * This function allows the user to browse through the loaded specimens, given the direction.
   * When the end of the loaded specimen array has been reached, the function will loop to the beginning
   * of the array again
   * @param direction string, denoting whether we are scrolling left or right
   * @author Luuk
   */
  protected browse(direction: string): void {
    let index = this.helper.specimen_list.findIndex(
      (specimen: Specimen) => specimen.identifier === this.current_specimen.identifier,
    );

    if (index == -1) {
      this.allow_browse = false;
    }

    if (direction == "left") {
      index = index >= 1 ? index - 1 : this.helper.specimen_list.length - 1;
    } else {
      index = index < this.helper.specimen_list.length - 1 ? index + 1 : 0;
    }
    this.current_specimen = this.helper.specimen_list[index];
    this.shown_index = index + 1;
    // Retrieve all connected information
    this.update_meta_tags();
    // Now retrieve the linked multimedia
    this.request_linked_multimedia();
    // And the linked assemblages
    this.request_linked_assemblage();
    // Add the specimen position to our marker list
    this.map_marker_positions = [];
    this.map_marker_positions.push(this.current_specimen.position);
    // Check if the listed taxa exist on the nba
    this.current_specimen.identifications.forEach((element) => {
      this.nba.request_taxon_existence(element.scientificName.scientificNameGroup);
    });
    this.location.replaceState(`${this.translate.currentLang}/specimen/${this.current_specimen.registration_number}`);
  }
}

export class ScientificName {
  authorshipVerbatim = "—";
  fullScientificName = "—";
  genusOrMonomial = "—";
  infraSpecificEpithet = "—";
  scientificNameGroup = "—";
  specificEpithet = "—";

  constructor(content: any) {
    this.set(content);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param content with JSON data received from the server
   * @author Luuk
   */
  public set(content: any) {
    this.authorshipVerbatim = "authorshipVerbatim" in content ? content["authorshipVerbatim"] : "";
    this.fullScientificName = "fullScientificName" in content ? content["fullScientificName"] : "";
    this.genusOrMonomial = "genusOrMonomial" in content ? content["genusOrMonomial"] : "";
    this.infraSpecificEpithet = "infraSpecificEpithet" in content ? content["infraSpecificEpithet"] : "";
    this.scientificNameGroup = "scientificNameGroup" in content ? content["scientificNameGroup"] : "";
    this.specificEpithet = "specificEpithet " in content ? content["specificEpithet"] : "";
  }
}

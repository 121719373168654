// Library imports
import { Component, OnInit, Input } from "@angular/core";
import { environment } from "@src/environments/environment";

// Service imports
import { NavigatorService } from "@bioportal/services/navigator.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { SearchFormService } from "@bioportal/services/search/search-form.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";
import { HelperService } from "@bioportal/services/helper.service";

// Model imports
import { Search_data } from "@bioportal/models/Search_data";

export interface Show {
  overview: boolean;
  multimedia: boolean;
  specimen: boolean;
  taxa: boolean;
  [key: string]: boolean | string;
}

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit {
  @Input() search_data: Search_data;

  protected show: Show;

  protected geosearch = false;
  protected polygon_search = false;
  protected area_search = false;

  // Data objects that are passed to overview children
  protected specimen_data: any = [];
  protected taxa_data: any = [];
  protected multimedia_data: any = [];

  constructor(
    protected helper: HelperService,
    protected navigator: NavigatorService,
    protected nba: NbaService,
    protected search_form: SearchFormService,
    protected translate: TranslateService,
    protected utility: UtilityService,
  ) {}

  ngOnInit(): void {
    // Initialise the show interface, which manages which components we will show
    this.show = {
      overview: false,
      multimedia: false,
      specimen: false,
      taxa: false,
    };

    this.geosearch = this.search_data.geosearch;
    this.polygon_search = this.utility.is_empty_array(this.search_data.polygon) ? false : true;
    this.area_search = this.search_data.area == "" ? false : true;

    // If an endpoint was provided in the search data, we will show all results for that endpoint.
    // If not, we will provide an overview, using the default sizes specified in Search_data
    if (this.search_data.endpoint) {
      this.search_data.size = environment.maxNbaResults;
      this.show[this.search_data.endpoint] = true;
    } else {
      this.show = this.utility.set_object_booleans(this.show, false);
      this.show.overview = true;
    }

    // Request our specimen, taxa and multimedia data
    if (this.show.specimen || this.show.overview) {
      this.request_specimen();
    }

    if (this.show.taxa || this.show.overview) {
      this.request_taxa();
    }

    // The multimedia list is handled by the multimedia-list component
    if (this.show.overview) {
      this.request_multimedia();
    }

    this.update_meta_tags();
    // If the language changes, we want the name of the selected area to be localized as well.
    this.get_language_change_subscription();
  }

  /**
   * Watches for language changes. When change occurs, will localize the language of country names in the geosearch
   * @author Luuk
   */
  private get_language_change_subscription() {
    this.translate.onLangChange.subscribe({
      next: () => {
        if (this.translate.currentLang == "en") {
          this.search_form.selected_area["localized"] = this.search_form.selected_area.locality;
        } else {
          this.search_form.selected_area["localized"] = this.search_form.selected_area.countryNL;
        }
      },
    });
  }

  private request_specimen(): void {
    this.nba
      .get_data(
        this.nba.convert_search_data_to_query_spec(this.search_data, environment.specimensDenominator),
        environment.specimensDenominator,
      )
      .subscribe((data) => {
        this.specimen_data = data;
      });
  }
  private request_taxa(): void {
    this.nba
      .get_data(
        this.nba.convert_search_data_to_query_spec(this.search_data, environment.taxaDenominator),
        environment.taxaDenominator,
      )
      .subscribe((data) => {
        this.taxa_data = data;
      });
  }
  private request_multimedia(): void {
    this.nba
      .get_data(
        this.nba.convert_search_data_to_query_spec(this.search_data, environment.multimediaDenominator),
        environment.multimediaDenominator,
      )
      .subscribe((data) => {
        this.multimedia_data = data;
      });
  }

  public update_meta_tags() {
    let title = this.translate.instant("search.search_results");
    if (this.search_form.form.value.basic_term !== "") {
      title = this.translate.instant("search.search_results_for") + ": '" + this.search_form.form.value.basic_term;
    }
    this.utility.update_meta_tags(title);
  }

  public show_results(show_domain: string): boolean {
    return show_domain === this.search_form.active_domain || this.search_form.active_domain === "all";
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BioportalComponent } from "@bioportal/bioportal/bioportal.component";

// Routes to take. Disallows Path Traversal.
const routes: Routes = [
  // Legacy paths (:none discards old query parameters that are now longer supported)
  { path: "result", component: BioportalComponent },
  { path: "multimedia/:id/:none", component: BioportalComponent },
  { path: "specimen/:id/:none", component: BioportalComponent },
  { path: "specimen/:id/:none/:none", component: BioportalComponent },
  { path: "specimen/:id", component: BioportalComponent },
  { path: "taxon/:id", component: BioportalComponent },
  { path: "taxon/:id/:none", component: BioportalComponent },
  { path: "dashboard", component: BioportalComponent },

  // Two routes that will lead to the Search component
  { path: "", component: BioportalComponent },
  { path: ":language", component: BioportalComponent },

  /**
   * Possible pages:
   * result -> shows results for all endpoints, accepts query parameters
   * dashboard -> shows the dashboard
   */
  { path: ":language/:page", component: BioportalComponent },

  /**
   * This route is used for the results for a highlight: endpoint is the media type, page_type is always
   * "highlight", with id being the slug of the highlight
   */
  { path: ":language/:page/:endpoint/:page_type/:id", component: BioportalComponent }, // Uses UrlQuery

  /**
   * Used for specific result pages, like /en/result/multimedia/. Accepts query parameters.
   * taxon -> shows results for a single taxon item
   * multimedia -> shows results for a single multimedia item
   * specimen -> shows results for a specimen item
   */
  { path: ":language/:page/:endpoint", component: BioportalComponent }, // Uses UrlQuery

  { path: "**", component: BioportalComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<app-query-printer></app-query-printer>
<div style="padding: 1em" *ngIf="this.isFound">
  <h1>{{ this.current_content.title }}</h1>
  <div id="introduction" [innerHTML]="this.current_content.body"></div>

  <h2 class="pt-2">Multimedia {{ this.utility.get_formatted_query_result_size(this.multimedia_data.totalSize) }}</h2>
  <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['multimedia']"></mat-progress-bar>
  <app-multimedia-viewer
    id="multimedia-list"
    *ngIf="this.multimedia_data.totalSize > 0"
    (multimedia_item_request)="this.navigator.navigate_to_multimedia_item($event)"
    [images]="this.multimedia_data.result_set"></app-multimedia-viewer>
  <div *ngIf="this.multimedia_data.totalSize > 0" style="padding: 0.5em">
    <a class="a-no-redirect" (click)="this.load_all_multimedia()" id="load_all_multimedia">
      {{ "search.show_all" | translate }}
      {{ this.multimedia_data.totalSize.toLocaleString(this.translate.currentLang) }}
      {{ "search.results" | translate }}.
    </a>
  </div>

  <h2 class="pt-2">Specimens {{ this.utility.get_formatted_query_result_size(this.specimen_data.totalSize) }}</h2>
  <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['specimens']"></mat-progress-bar>
  <app-specimen-table
    *ngIf="this.specimen_data.totalSize > 0"
    [specimen_data]="this.specimen_data"
    id="specimen-table"></app-specimen-table>
  <div class="pt-1" *ngIf="this.specimen_data.totalSize > 0" style="padding: 0.5em">
    <a class="a-no-redirect" (click)="this.load_all_specimens()">
      {{ "search.show_all" | translate }}
      {{ this.specimen_data.totalSize.toLocaleString(this.translate.currentLang) }}
      {{ "search.results" | translate }}.
    </a>
  </div>
</div>

<div style="padding: 1em" *ngIf="this.isFound === false">
  <h1>{{ "highlights.not_found_title" | translate }}</h1>
  <p>{{ "highlights.not_found_body" | translate }}</p>
</div>

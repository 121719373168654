import { Component, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";

// Service imports
import { NavigatorService } from "@bioportal/services/navigator.service";
import { NbaService } from "@bioportal/services/api/nba.service";
import { UtilityService } from "@bioportal/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { SearchFormService } from "@bioportal/services/search/search-form.service";

// Model imports
import { Taxon } from "@bioportal/models/Taxon";
import { environment } from "@src/environments/environment";
import { Meta, Title } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { Search_data } from "@bioportal/models/Search_data";

@Component({
  selector: "app-taxon-item",
  templateUrl: "./taxon-item.component.html",
  styleUrls: ["./taxon-item.component.scss"],
})
export class TaxonItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() search_data: Search_data;
  @Input() is_server: boolean;
  @Output() page_not_found = new EventEmitter<void>();

  id: string;
  data: any;
  isFound: boolean;

  nba_search_term: string;

  protected current_taxa: Taxon[] = [];
  protected no_synonyms = true;
  protected no_descriptions = true;
  protected no_vernaculars = true;
  protected no_classifications = true;

  protected specimen_data: any = [];
  protected multimedia_data: any = [];

  constructor(
    protected translate: TranslateService,
    protected navigator: NavigatorService,
    protected nba: NbaService,
    protected utility: UtilityService,
    private location: Location,
    private meta: Meta,
    private title_service: Title,
    protected search_form: SearchFormService,
  ) {}

  ngOnInit() {
    this.init_values();

    // Lowercase the search term to fix legacy parameters
    this.search_data.search_term = this.search_data.search_term.toLowerCase();
    this.search_data.size = environment.maxTaxonResults;
    this.search_data.page_type = "taxon-item";

    const query_spec = this.nba.convert_search_data_to_query_spec(this.search_data, environment.taxaDenominator);
    this.request_taxa(query_spec);
  }

  /**
   * Sends the given query spec to the NBA and processes incoming data
   * @param query_spec (object)
   * @author Luuk
   */
  private request_taxa(query_spec: any): void {
    this.nba.get_data(query_spec, environment.taxaDenominator).subscribe((data) => {
      this.init_values();

      if (data.totalSize > 0) {
        this.isFound = true;
        for (const taxon in data.resultSet) {
          const new_taxon = new Taxon();
          new_taxon.set(data.resultSet[taxon].item);
          this.current_taxa.push(new_taxon);
          this.nba_search_term = new_taxon.scientific_name_group;
        }
        // Check if we have synonyms, descriptions
        for (const taxon in this.current_taxa) {
          if (this.current_taxa[taxon].has_synonyms) {
            this.no_synonyms = false;
          }
          if (this.current_taxa[taxon].has_classification) {
            this.no_classifications = false;
          }
          if (this.current_taxa[taxon].has_vernaculars) {
            this.no_vernaculars = false;
          }
          this.localize_description(this.current_taxa[taxon]);
        }
        // Now retrieve all connected multimedia and specimens using the scientific name
        const query_spec = this.nba.new_query_spec();
        for (const taxon in this.current_taxa) {
          const condition = this.nba.create_query_condition(
            "identifications.scientificName.scientificNameGroup",
            "EQUALS",
            this.current_taxa[taxon].scientific_name_group,
            "taxa",
          );
          query_spec["conditions"].push(condition);
        }
        query_spec["logicalOperator"] = "OR";

        this.nba.clear_data_object(environment.specimensDenominator);
        this.nba.clear_data_object(environment.multimediaDenominator);

        this.request_specimen(query_spec);
        this.request_multimedia(query_spec);

        this.update_meta_tags();
      } else if (data.totalSize == 0) {
        // If no results, show 404.
        this.isFound = false;
        this.page_not_found.emit();
      }
    });
  }

  ngAfterViewInit() {
    this.translate.onLangChange.subscribe({
      next: () => {
        this.no_descriptions = true;
        for (const taxon in this.current_taxa) {
          this.localize_description(this.current_taxa[taxon]);
        }
      },
    });
  }

  ngOnDestroy() {
    this.current_taxa = [];
  }

  private request_specimen(query_spec: any): void {
    this.nba.get_data(query_spec, environment.specimensDenominator).subscribe((data) => {
      this.specimen_data = data;
    });
  }
  private request_multimedia(query_spec: any): void {
    this.nba.get_data(query_spec, environment.multimediaDenominator).subscribe((data) => {
      this.multimedia_data = data;
    });
  }

  private localize_description(taxon: Taxon): void {
    if (this.translate.currentLang == "en" && taxon.has_english_description) {
      this.no_descriptions = false;
    } else if (this.translate.currentLang == "nl" && taxon.has_dutch_description) {
      this.no_descriptions = false;
    }
  }

  private init_values(): void {
    this.current_taxa = [];
    this.no_synonyms = true;
    this.no_descriptions = true;
    this.no_vernaculars = true;
  }

  public update_meta_tags() {
    const taxon = this.current_taxa[0];

    const title = taxon.scientific_name + " | " + environment.appTitle;

    this.title_service.setTitle(title);
    this.meta.updateTag({ name: "title", content: title });
    this.meta.updateTag({ property: "og:title", content: title });

    const content = "Taxon '" + taxon.scientific_name + "' in the Naturalis Bioportal.";

    this.meta.updateTag({
      name: "description",
      content: this.utility.strip_html_tags(content),
    });
    this.meta.updateTag({
      property: "og:description",
      content: this.utility.strip_html_tags(content),
    });
    //this.meta.updateTag({ property: "og:image", content: this.current_multimedia.medium_image() });

    const location = new URL(this.location.path(), environment.baseUrl);
    this.meta.updateTag({ name: "url", content: location.toString() });
    this.meta.updateTag({ property: "og:url", content: location.toString() });
  }

  /**
   * Loads all results using the saved query and the new amount of hits requested
   * @param category for which to load the results
   * @param amount of hits we want to retrieve
   * @author Luuk
   */
  protected load_all_specimen(): void {
    const navigation_link = environment.internalSpecimenResultLink + "taxa/";
    this.utility.navigate_to(navigation_link, this.nba_search_term);
  }

  /**
   * Loads all results using the saved query and the new amount of hits requested
   * @param category for which to load the results
   * @param amount of hits we want to retrieve
   * @author Luuk
   */
  protected load_all_multimedia(): void {
    const navigation_link = environment.internalMultimediaResultLink + "taxa/";
    this.utility.navigate_to(navigation_link, this.nba_search_term);
  }
}

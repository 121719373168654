import { Component, isDevMode, OnDestroy } from "@angular/core";
import { environment } from "@src/environments/environment";

// Service imports
import { NbaService } from "@bioportal/services/api/nba.service";
import { UtilityService } from "@bioportal/services/utility.service";

@Component({
  selector: "app-query-printer",
  templateUrl: "./query-printer.component.html",
  styleUrls: ["./query-printer.component.scss"],
})
export class QueryPrinterComponent implements OnDestroy {
  constructor(
    protected nba: NbaService,
    protected utility: UtilityService,
  ) {}

  ngOnDestroy(): void {
    // Reset the query spec list when navigating away from this component.
    this.nba.query_spec_list = [];
  }

  protected isDebugging(): boolean {
    return environment.debuggingMode && isDevMode();
  }
}

<div class="p-2 multimedia-image mat-dialog-container" *ngIf="this.isFound">
  <div class="mat-dialog-title">
    <div class="btn-group-sm float-end">
      <a class="btn btn-light btn-sm" href="{{ this.current_multimedia.uri }}" target="_blank">
        <mat-icon fontIcon="zoom_in"></mat-icon>
      </a>
      <a
        *ngIf="this.utility.master_image(this.current_multimedia.uri)"
        class="btn btn-light btn-sm"
        href="{{ this.utility.master_image(this.current_multimedia.uri) }}"
        target="_blank">
        <mat-icon fontIcon="download"></mat-icon>
      </a>
      <a
        class="btn btn-light btn-sm"
        (click)="this.browse_images('left')"
        *ngIf="this.helper.multimedia_list"
        title="<-">
        <mat-icon fontIcon="navigate_before"></mat-icon>
      </a>
      <a
        class="btn btn-light btn-sm"
        (click)="this.browse_images('right')"
        *ngIf="this.helper.multimedia_list"
        title="->">
        <mat-icon fontIcon="navigate_next"></mat-icon>
      </a>
    </div>
    <h3>{{ this.current_multimedia.registration_number() }}</h3>
    <h4 *ngIf="this.current_multimedia.has_scientific_name()">
      <span *ngFor="let identification of this.current_multimedia.identifications; first as isFirst">
        <span *ngIf="!isFirst"> / </span>
        {{ identification.scientificName.fullScientificName }}
      </span>
    </h4>
  </div>

  <div class="row">
    <div class="col-md-6">
      <figure [ngClass]="{ 'placeholder-wave': this.current_multimedia.loading }">
        <a href="{{ this.current_multimedia.uri }}" target="_blank" *ngIf="this.current_multimedia.is_image()">
          <img
            src="{{ this.current_multimedia.uri }}"
            alt="{{ this.current_multimedia.registration_number() }}"
            loading="lazy"
            (load)="this.current_multimedia.readyLoading()" />
        </a>
        <a href="{{ this.current_multimedia.uri }}" target="_blank" *ngIf="this.current_multimedia.is_video()">
          <video
            src="{{ this.current_multimedia.uri }}"
            type="video/mp4"
            autoplay=""
            controls=""
            loading="lazy"></video>
        </a>
      </figure>
    </div>

    <div class="col-md-6">
      <table class="table table-striped">
        <tbody>
          <tr *ngIf="!this.current_multimedia.is_nsr && !this.current_multimedia.is_dcsr">
            <th class="col-4" id="cy_registration_number">
              {{ "search.registration_number" | translate }}
            </th>
            <td class="col-8">
              <a
                id="cy-registration-link"
                class="a-no-redirect"
                (click)="this.utility.navigate_to('/specimen', data.specimen_id)">
                {{ this.current_multimedia.registration_number() }}
              </a>
            </td>
          </tr>

          <tr *ngIf="this.current_multimedia.has_scientific_name()">
            <th>
              {{ "search.scientific_name" | translate }}
            </th>
            <td>
              <ng-container *ngFor="let identification of this.current_multimedia.identifications">
                <div
                  *ngIf="
                    this.nba.get_taxon_existence(identification.scientificName.scientificNameGroup);
                    else taxon_does_not_exist
                  ">
                  <a
                    class="a-no-redirect"
                    mat-dialog-close
                    (click)="this.utility.navigate_to('/taxon', identification.scientificName.scientificNameGroup)">
                    {{ this.utility.capitalize_first_letter(identification.scientificName.fullScientificName) }}
                  </a>
                </div>
                <ng-template #taxon_does_not_exist>
                  <div>
                    {{ this.utility.capitalize_first_letter(identification.scientificName.fullScientificName) }}
                  </div>
                </ng-template>
              </ng-container>
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.source" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.source }}
            </td>
          </tr>

          <tr *ngIf="this.current_multimedia.is_nsr || this.current_multimedia.is_dcsr">
            <th id="cy_author">
              {{ "search.author" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.creator }}
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.license" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.license }}
            </td>
          </tr>

          <tr *ngIf="this.current_multimedia.is_nsr || this.current_multimedia.is_dcsr">
            <th>
              {{ "search.copyright" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.copyright }}
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.institution" | translate }}
            </th>
            <td id="cy_institution">
              {{ this.current_multimedia.institution }}
            </td>
          </tr>

          <tr *ngIf="this.current_multimedia.is_nsr || this.current_multimedia.is_dcsr">
            <th>
              {{ "table.description" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.description }}
            </td>
          </tr>

          <tr *ngIf="!this.current_multimedia.is_nsr && !this.current_multimedia.is_dcsr">
            <th>
              {{ "search.collection_name" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.collection_name }}
            </td>
          </tr>

          <!-- TODO: Properly handle all possible locations -->
          <tr>
            <th>
              {{ "search.locality" | translate }}
            </th>
            <td *ngIf="!this.current_multimedia.is_nsr && !this.current_multimedia.is_dcsr">
              <ng-container *ngFor="let object of this.current_multimedia.gathering_events">
                {{ object["country"] }} {{ object["sublocality"] }}
              </ng-container>
            </td>
            <td *ngIf="this.current_multimedia.is_nsr || this.current_multimedia.is_dcsr">
              <ng-container *ngFor="let object of this.current_multimedia.gathering_events">
                {{ object["localityText"] }}
              </ng-container>
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.date" | translate }}
            </th>
            <td *ngIf="this.current_multimedia.is_nsr || this.current_multimedia.is_dcsr">
              {{ this.current_multimedia.date }}
            </td>
            <td *ngIf="!this.current_multimedia.is_nsr && !this.current_multimedia.is_dcsr">
              {{ this.current_multimedia.collection_start_date }}
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.phase_stage" | translate }}
            </th>
            <td>
              <ng-container *ngFor="let stage of this.current_multimedia.phase_stage">
                {{ stage }}
              </ng-container>
            </td>
          </tr>

          <tr>
            <th>
              {{ "search.sex" | translate }}
            </th>
            <td>
              {{ this.current_multimedia.sex }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="pt-1" *ngIf="this.isFound === false">
  <h1>{{ "multimedia.not_found_title" | translate }}</h1>
  <p>{{ "multimedia.not_found_body" | translate }}</p>
</div>

<div class="container">
  <div class="row pt-3 pb-3">
    <div class="col-lg-8">
      <!-- Total number of specimens -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title class="justify-content-center">
            {{ "dashboard.naturalis_dashboard" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content [innerHTML]="'dashboard.introduction' | translate"></mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-4">
      <!-- Total number of multimedia -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.export_data" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let row of update_date_table">
                <th class="col-6">{{ row.label | translate }}</th>
                <td class="col-6 text-end">
                  {{ this.update_dates[row.property] | date: "dd-MM-yyyy" }}
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- SECOND ROW: main counts -->
  <div class="row pt-3 pb-3">
    <div class="col-lg-4 text-center">
      <!-- Total number of specimens -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title class="justify-content-center">
            {{ "dashboard.specimen_count" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h3 id="cy_specimen_count" class="huge-colored-count">{{ this.specimen_total_count }}</h3>
          <h4>specimens</h4>
          <p>
            {{ "dashboard.specimen_count_1" | translate }} {{ this.specimen_number_of_records }}
            {{ "dashboard.specimen_count_2" | translate }} {{ this.specimen_storage_units }}
            {{ "dashboard.specimen_count_3" | translate }}.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-4 pb-lg-0 text-center">
      <!-- Total number of taxa -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.taxon_count" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h3 class="huge-colored-count">{{ this.taxon_total_count }}</h3>
          <h4>taxa</h4>
          <p>{{ "dashboard.taxon_count_subtext" | translate }}</p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-4 text-center">
      <!-- Total number of multimedia -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.multimedia_count" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h3 class="huge-colored-count">{{ this.multimedia_total_count }}</h3>
          <h4>multimedia records</h4>
          <p>{{ "dashboard.multimedia_count_subtext" | translate }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- THIRD ROW: count by categories -->
  <div class="row pt-3">
    <h2 style="text-align: center">
      {{ "dashboard.collection_categories_specimen_count" | translate }}
    </h2>
    <div class="col-lg-6">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let item of this.collection_categories_by_specimen_count">
            <td class="col-6">
              {{ item[0] }}
            </td>
            <td class="col-6 text-end">
              {{ item[1].toLocaleString(this.translate.currentLang) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-lg-6">
      <google-chart
        class="pie-chart"
        #chart
        [type]="this.specimen_pie.type"
        [data]="this.specimen_pie.data"
        [columns]="this.specimen_pie.columnNames"
        [options]="this.specimen_pie.options">
      </google-chart>
    </div>
  </div>

  <!-- FOURTH ROW: count by name -->
  <div class="row pt-3 pb-3">
    <div class="col-lg-4 pb-lg-0 pb-md-2 text-center">
      <!-- Total number of specimens -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.taxa_per_rank" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let item of this.taxon_per_rank">
                <th class="col-8 text-start" id="cy_taxa_per_rank">
                  {{ item[0] }}
                </th>
                <td class="col-4 text-end">
                  {{ item[1].toLocaleString(this.translate.currentLang) }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="small">
            {{ "dashboard.taxa_per_rank_subtext" | translate }}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-4 pb-lg-0 pb-md-2 text-center">
      <!-- Total number of taxa -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.unique_scientific_names_with_specimens" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
          <h2 class="huge-colored-count">{{ this.unique_scientific_names_with_specimens }}</h2>
          <h3>
            {{ "dashboard.full_scientific_names" | translate }}
          </h3>
          <p class="small">
            {{ "dashboard.unique_scientific_names_with_specimens_subtext" | translate }}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-4 pb-lg-0 pb-md-2 text-center">
      <!-- Total number of multimedia -->
      <mat-card class="count-card">
        <mat-card-header>
          <mat-card-title>
            {{ "dashboard.names" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="justify-content-center">
          <h3 class="huge-colored-count">{{ this.taxonomic_accepted_names }}</h3>
          <p>{{ "dashboard.unique_accepted_names" | translate }}</p>
          <h3 class="huge-colored-count">{{ this.unique_synonyms }}</h3>
          <p>{{ "dashboard.unique_synonyms" | translate }}</p>
          <h3 class="huge-colored-count">{{ this.unique_common_names }}</h3>
          <p>{{ "dashboard.unique_vernacular_names" | translate }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- FIFTH ROW: geographic count in Netherlands -->
  <div class="row pt-3 pb-3">
    <h2 class="text-center">
      {{ "dashboard.specimens_per_province" | translate }}
    </h2>
    <div class="col-lg-6">
      <google-chart
        class="netherlands-chart"
        #chart
        [type]="this.province_chart.type"
        [data]="this.province_chart.data"
        [options]="this.province_chart.options">
      </google-chart>
    </div>

    <div class="col-lg-6">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let item of this.province_list">
            <th class="col-6">
              {{ item.name }}
            </th>
            <td class="col-3 text-end">
              {{ item.count.toLocaleString(this.translate.currentLang) }}
            </td>
            <td class="col-3 text-end">{{ item.percentage }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- SIXTH ROW: geographic count world -->
  <div class="row pt-3 pb-3">
    <h2 style="text-align: center">
      {{ "dashboard.specimens_per_country" | translate }}
    </h2>

    <div class="col-lg-6">
      <google-chart
        class="world-chart"
        #chart
        [type]="this.country_chart.type"
        [data]="this.country_chart.data"
        [options]="this.country_chart.options">
      </google-chart>
    </div>

    <div class="col-lg-6">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let item of this.country_list">
            <th class="col-9">
              {{ item[0] }}
            </th>
            <td class="col-3 text-end">
              {{ item[1].toLocaleString(this.translate.currentLang) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- SEVENTH ROW: count by type of collection -->
  <div class="row pt-3 pb-3">
    <h2 style="text-align: center">
      {{ "dashboard.typestatus_per_collection" | translate }}
    </h2>

    <ng-container *ngFor="let item of this.typestatus_records_per_collection">
      <div class="col-md-4 collection">
        <h4>
          {{ item.collectionType }} ({{ item.count.toLocaleString(this.translate.currentLang) }}
          {{ "dashboard.total" | translate }})
        </h4>
        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let type of item.values">
              <th class="col-10">
                <a
                  class="a-no-redirect"
                  (click)="this.route_collection_type(item.collectionType, type['identifications.typeStatus'])"
                  >{{ type["identifications.typeStatus"] }}</a
                >
              </th>
              <td class="col-2 text-end">
                {{ type.count.toLocaleString(this.translate.currentLang) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <p class="text-center small">
      {{ "dashboard.typestatus_per_collection_subtext" | translate }}
    </p>
  </div>

  <!-- SEVENTH ROW: collectors and (sub)species -->
  <div class="row pt-3 pb-3">
    <div class="col-lg-6">
      <h2 class="text-center">
        {{ "dashboard.top_collectors" | translate }}
      </h2>

      <ng-container *ngFor="let item of this.top_collectors">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header class="right-aligned-header">
            <mat-panel-title>
              {{ item.name }}
            </mat-panel-title>
            <mat-panel-description class="text-end">
              {{ item.count.toLocaleString(this.locale) }} {{ "dashboard.total" | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let collection of item.values">
                <td class="col-9">
                  <a class="a-no-redirect" (click)="this.route_top_collector(item.name, collection.collectionType)">{{
                    collection.collectionType
                  }}</a>
                </td>
                <td class="col-3 text-end">
                  {{ collection.count.toLocaleString(this.locale) }}
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </ng-container>

      <p class="text-center small pt-2">
        {{ "dashboard.top_collectors_subtext" | translate }}
      </p>
    </div>
    <div class="col-lg-6">
      <h2 class="text-center">
        {{ "dashboard.most_collected_subspecies" | translate }}
      </h2>

      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let item of this.top_collected_subspecies">
            <td class="col-9">
              <a class="a-no-redirect" (click)="this.route_scientific_name(item[0])">{{ item[0] }}</a>
            </td>
            <td class="col-3 text-end">
              {{ item[1].toLocaleString(this.locale) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="text-center small">
        {{ "dashboard.most_collected_subspecies_subtext" | translate }}
      </p>
    </div>
  </div>
</div>

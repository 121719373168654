import { Injectable } from "@angular/core";
import { NbaService } from "@bioportal/services/api/nba.service";
import { ReplaySubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";

@Injectable({
  providedIn: "root",
})
export class MapsService {
  geo_data: any;
  // Save the polygons we have drawn or selected
  polygons: any[] = [];
  // Save the marker positions
  marker_positions: google.maps.LatLngLiteral[] = [];
  // Declare markers. Will be initialized by calling component
  svg_marker: any;
  marker_options: any = {};
  // Center the default map on the Netherlands
  center: google.maps.LatLngLiteral = {
    lat: 52.2,
    lng: 5.61,
  };
  // Provide a default zoom level
  zoom = 7;
  // Options for the interface of the map
  options: google.maps.MapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeId: "hybrid",
  };
  // Keep track of the locations we can browse through
  location_list: any;
  selected_location = "";

  bounds: any;
  polygon_update = new ReplaySubject(0);

  constructor(
    private nba: NbaService,
    private translate: TranslateService,
    private utility: UtilityService,
  ) {
    // If we change the language, we need to change the readout of our locations
    this.translate.onLangChange.subscribe(() => {
      this.localize_locations();
    });
  }

  /**
   * Given the current language, this function localizes the locality of the current
   * location list. In short, it will put the correct language names in a new field that
   * will be read by the frontend
   * @author Luuk
   */
  public localize_locations(): void {
    if (this.translate.currentLang == "nl") {
      for (const location in this.location_list) {
        this.location_list[location]["localized"] = this.location_list[location].countryNL;
      }
    } else {
      for (const location in this.location_list) {
        this.location_list[location]["localized"] = this.location_list[location].locality;
      }
    }
  }

  public no_location_selected(): boolean {
    return this.selected_location == "";
  }

  public no_polygon_drawn(): boolean {
    return this.polygons.length == 0;
  }

  /**
   * Changes the location list based on the given location. For example, will
   * switch to municipalities when the corresponding location is provided.
   * @param location
   * @author Luuk
   */
  public change_location_list(location: string): void {
    this.location_list = this.geo_data[location];
    this.localize_locations();
    this.sort_object_list(this.location_list, "localized");
  }

  /**
   * Alphabetically sorts a list given the key
   * @param object_list that needs to be sorted
   * @field on which we want to sort
   * @author Luuk
   */
  public sort_object_list(object_list: object[], field: string): object[] {
    object_list.sort((a: any, b: any) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
    return object_list;
  }

  /**
   * Adds a marker to the map based on the given event. Also creates a polygon
   * from the markers to be displayed on screen.
   * @param event containing marker information
   * @author Luuk
   */
  public add_marker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) {
      this.marker_positions.push(event.latLng.toJSON());
      // We need to recreate the entire polygon to force the map to redraw the structure
      // Pushing to the array does not work. Notice that we push the polygon as a list in the
      // polygons object.
      this.polygons = [structuredClone(this.marker_positions)];
      // Also, as we added a marker, we no longer have a location selected
      this.selected_location = "";
    }
  }

  /**
   * Clears the map of polygons and markers
   * @author Luuk
   */
  public clear_map(): void {
    this.polygons = [];
    this.marker_positions = [];
    this.selected_location = "";
  }

  /**
   * Retrieves the polygons from the server given the identifier of the area
   * @param id string representing the GEO id of the area. '<number>@GEO'
   * @author Luuk
   */
  public get_polygon(id: string): void {
    // The nba does not allow looking at id. So for now, we split it and use sourceSystemId
    const nba_acceptable_id = id.split("@")[0];
    // Create the query spec and push the correct condition
    const query_spec = this.nba.new_query_spec();
    query_spec["conditions"].push(this.nba.create_query_condition("sourceSystemId", "EQUALS", nba_acceptable_id));
    const query = this.nba.create_query(query_spec, "geo");
    this.retrieve_polygon(query);
  }

  /**
   * Retrieves the polygon from the Nba. After retrieval, the polygon will be
   * shaped into a google maps acceptable format
   * @param query to be send to the nba
   * @author Luuk
   */
  private retrieve_polygon(query: string): void {
    this.nba.get(query).subscribe({
      next: (data) => {
        const polygon_type = data.resultSet[0].item.shape.type;
        // We need to convert the NBA polygons to google maps polygons
        let polygons = data.resultSet[0].item.shape.coordinates;
        if (polygon_type == "Polygon") {
          // Turn the polygon into a MultiPolygon
          polygons = [polygons];
        }
        for (const i in polygons) {
          // this.bounds.extend(polygons[i].getPosition());
          // Loop through all the polygons provided for a single area
          const single_polygon_array = [];
          // The nested encoding of polygons is rather strange.
          for (let j = 0; j < polygons[i][0].length; j++) {
            single_polygon_array.push(
              new google.maps.LatLng(
                // Nba uses lng,lat, google lat,lng
                polygons[i][0][j][1],
                polygons[i][0][j][0],
              ),
            );
          }
          // And push the polygon to the list of polygons
          this.polygons.push(single_polygon_array);
          this.bounds = this.compute_bounding_box(this.polygons);
          // Let subscribers know we updated the polygon
          this.polygon_update.next(1);
        }
      },
      error: (err) => {
        this.utility.show_error_message("errors.nba"), console.debug(err);
      },
    });
  }

  /**
   * Computes the bounding box of a polygon or multipolygon by finding its most
   * eastern, western, northern and southern points
   * @param multipolygon
   * @author Luuk
   */
  private compute_bounding_box(multipolygon: any): object {
    let north = -999;
    let south = 999;
    let west = 999;
    let east = -999;

    for (const polygon in multipolygon) {
      for (const coordinate in multipolygon[polygon]) {
        const lat = multipolygon[polygon][coordinate].toJSON().lat;
        const lng = multipolygon[polygon][coordinate].toJSON().lng;

        if (lng > east) {
          east = lng;
        }
        if (lng < west) {
          west = lng;
        }
        if (lat > north) {
          north = lat;
        }
        if (lat < south) {
          south = lat;
        }
      }
    }

    return {
      north: north,
      south: south,
      west: west,
      east: east,
    };
  }

  /**
   * Initializes the map markers. Should be called AfterViewInit.
   */
  public init_map_markers(): void {
    // Declare the marker we will put on the map
    this.svg_marker = {
      // https://developers.google.com/maps/documentation/javascript/symbols
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "red",
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 5,
    };
    // Options for the markers
    this.marker_options = {
      draggable: false,
      icon: this.svg_marker,
    };
  }
}

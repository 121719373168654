/**
 * Typescript object to handle all the incoming content pages from the JSON data
 */
export class Content {
  body = "";
  title = "";

  constructor(title = "", body = "") {
    // Allow the partial initialisation of a content object
    this.title = title;
    this.body = body;
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param content with JSON data received from the server
   * @author Luuk
   */
  public set(content: any) {
    this.body = "body" in content ? content["body"]["value"] : "";
    this.title = "title" in content ? content["title"] : "";
  }
}

/**
 * To take advantage of loading the translation files on the server-side,
 * this loader fetches the previously loaded data from TransferState.
 * If it does not find the translations in the cache, it uses the HTTP loader
 * to get them.
 */
import { Observable } from "rxjs";
import { TranslateLoader } from "@ngx-translate/core";

import { makeStateKey, StateKey, TransferState } from "@angular/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";

export class TranslateBrowserService implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>("transfer-translate-" + lang);
    const data = this.transferState.get(key, null);

    // First we are looking for the translations in transfer-state,
    // if none found, http load as fallback
    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new TranslateHttpLoader(this.http).getTranslation(lang);
    }
  }
}

export function translateBrowserLoaderFactory(httpClient: HttpClient, transferState: TransferState) {
  return new TranslateBrowserService(httpClient, transferState);
}

/**
 * This component shows the 404 page for Bioportal
 */

import { Component } from "@angular/core";

// Service imports
import { NavigatorService } from "@bioportal/services/navigator.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrl: "./not-found.component.scss",
})
export class NotFoundComponent {
  constructor(protected navigator: NavigatorService) {}
}

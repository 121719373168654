import { environment } from "@src/environments/environment";

export class Search_data {
  search_fields: any = {
    basic_term: "",
    scientific_name: "",
    common_name: "",
    family: "",
    genus: "",
    epithet: "",
    registration_number: "",
    source: "",
    collection_name: "",
    type_status: [],
    type_material: [],
    locality: "",
    phase_stage: "",
    sex: "",
    collector: "",
    collector_field_number: "",
    license: [],
    kingdom: "",
    phylum: "",
    class: "",
    order: "",
    subgenus: "",
    infraspecific_name: "",
    old_barcodes: "",
    chronostratigraphy: "",
    lithostratigraphy: "",
    biostratigraphy: "",
  };

  // Geo related data
  area = "";
  polygon: any[] = [];

  operator = "OR";
  size: number = environment.defaultQuerySize;

  // Holds the endpoint provided by the URL
  endpoint: string;

  page_type = "";
  search_term = "";

  using_basic_term: boolean;

  url: string;
  save_identifier: string;

  // Keep track of the location/source we requested this search data from
  request_source: string;

  // Save original url parameters
  query_params: any;
  params: any;

  /** Service booleans */
  geosearch = false;
}

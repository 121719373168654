<app-query-printer></app-query-printer>
<div class="div-padding-lg"></div>

<!-- Main page layout -->
<div style="padding: 0.5em">
  <ng-container [ngTemplateOutlet]="search_result"></ng-container>
</div>

<ng-template #search_result>
  <div class="pt-2" id="overview-component">
    <!--Information to show on screen if we are on the overview screen-->
    <ng-container *ngIf="this.show.overview">
      <!-- Show the search term if we are using basic search -->
      <ng-container
        *ngIf="this.search_form.form.value.basic_term !== ''; then basic_results; else advanced_results"></ng-container>
      <ng-template #basic_results>
        <h1>
          {{ "search.search_results_for" | translate }}: <i>{{ this.search_form.form.value.basic_term }}</i>
        </h1>
      </ng-template>
      <!-- Otherwise just show a string with results -->
      <ng-template #advanced_results>
        <h1>{{ "search.search_results" | translate }}:</h1>
      </ng-template>

      <!-- Show banners if we are geosearching: both for area and polygon -->
      <ng-container *ngIf="this.area_search && this.helper.selected_area.localized">
        <div class="alert alert-success" role="alert">
          <b>{{ "search.area_search" | translate }}: {{ this.helper.selected_area.localized }}</b>
        </div>
      </ng-container>

      <ng-container *ngIf="this.polygon_search">
        <div class="alert alert-success" role="alert">
          <b>{{ "search.polygon_search" | translate }}.</b>
        </div>
      </ng-container>

      <hr />
    </ng-container>

    <!--Show the multimedia -->
    <ng-container *ngIf="this.show.multimedia || this.show.overview">
      <ng-container *ngIf="this.show_results('multimedia') && this.nba.query_applicable_on['multimedia']">
        <h2>Multimedia {{ this.utility.get_formatted_query_result_size(this.multimedia_data.totalSize) }}</h2>

        <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['multimedia']"></mat-progress-bar>

        <h4 *ngIf="this.multimedia_data.totalSize === 0 && !this.nba.loading_data['multimedia']">
          {{ "search.no_multimedia" | translate }}
        </h4>

        <!--If we are on a multimedia results page, we allow infinite scrolling-->
        <ng-container *ngIf="this.show.overview">
          <app-multimedia-viewer
            *ngIf="this.multimedia_data.totalSize > 0"
            (multimedia_item_request)="this.navigator.navigate_to_multimedia_item($event)"
            [images]="this.multimedia_data.result_set">
          </app-multimedia-viewer>
        </ng-container>

        <ng-container *ngIf="this.show.multimedia">
          <app-multimedia-list [search_data]="this.search_data"> </app-multimedia-list>
        </ng-container>

        <div *ngIf="this.multimedia_data.totalSize > 0 && this.show.overview" style="padding: 0.5em">
          <a
            class="a-no-redirect"
            (click)="this.navigator.load_all('multimedia', this.search_data.query_params)"
            id="cy-multimedia-link">
            {{ "search.show_all" | translate }}
            {{ this.multimedia_data.totalSize.toLocaleString(this.translate.currentLang) }}
            {{ "search.results" | translate }}.
          </a>
        </div>

        <hr />
      </ng-container>
    </ng-container>

    <!--Show the specimens-->
    <ng-container *ngIf="this.show.specimen || this.show.overview">
      <ng-container *ngIf="this.show_results('specimens') && this.nba.query_applicable_on['specimens']">
        <h2 id="specimen-header">
          Specimens {{ this.utility.get_formatted_query_result_size(this.specimen_data.totalSize) }}
        </h2>
        <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['specimens']"></mat-progress-bar>
        <h4 *ngIf="this.specimen_data.totalSize === 0 && !this.nba.loading_data['specimens']">
          {{ "search.no_specimens" | translate }}
        </h4>
        <app-specimen-table
          *ngIf="this.specimen_data.totalSize > 0"
          [specimen_data]="this.specimen_data"></app-specimen-table>
        <div *ngIf="this.specimen_data.totalSize > 0 && this.show.overview" style="padding: 0.5em">
          <a
            class="a-no-redirect"
            (click)="this.navigator.load_all('specimen', this.search_data.query_params)"
            id="cy-specimen-link">
            {{ "search.show_all" | translate }}
            {{ this.specimen_data.totalSize.toLocaleString(this.translate.currentLang) }}
            {{ "search.results" | translate }}.
          </a>
        </div>
        <hr />
      </ng-container>
    </ng-container>

    <!--Show the taxa-->
    <ng-container *ngIf="this.show.taxa || this.show.overview">
      <ng-container *ngIf="this.show_results('taxa') && this.nba.query_applicable_on['taxa'] && !this.geosearch">
        <h2>
          {{ "taxa.species_names" | translate }}
          {{ this.utility.get_formatted_query_result_size(this.taxa_data.totalSize) }}
          <i>({{ "taxa.matching" | translate }})</i>
        </h2>
        <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['taxa']"></mat-progress-bar>
        <h4 *ngIf="this.taxa_data.totalSize === 0 && !this.nba.loading_data['taxa']">
          {{ "search.no_taxa" | translate }}
        </h4>
        <app-taxa-table *ngIf="this.taxa_data.totalSize > 0" [taxa_data]="this.taxa_data"></app-taxa-table>
        <div *ngIf="this.taxa_data.totalSize > 0 && this.show.overview" style="padding: 0.5em">
          <a
            class="a-no-redirect"
            (click)="this.navigator.load_all('taxa', this.search_data.query_params)"
            id="taxa-content">
            {{ "search.show_all" | translate }}
            {{ this.taxa_data.totalSize.toLocaleString(this.translate.currentLang) }}
            {{ "search.results" | translate }}.
          </a>
        </div>
        <hr />
      </ng-container>
    </ng-container>
  </div>
</ng-template>

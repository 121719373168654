import { Component, OnInit } from "@angular/core";
import { DrupalService } from "@bioportal/services/api/drupal.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  footer_menu: any = [];

  constructor(
    private drupal: DrupalService,
    public translate: TranslateService,
    protected utility: UtilityService,
  ) {
    this.fill_footer_menu();
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe({
      next: () => {
        this.fill_footer_menu();
      },
    });
  }

  protected fill_footer_menu() {
    this.drupal.query_menu_content("footer").subscribe({
      next: (result: any) => {
        this.footer_menu = [];
        for (const item of result.data) {
          item.attributes["url"] = item.attributes["url"].replace("#", "/");
          this.footer_menu.push(item.attributes);
        }
      },
      error: (error: any) => {
        this.utility.show_error_message("errors.drupal"), console.debug(error);
      },
    });
  }
}

<div style="padding-top: 1em">
  <h4 *ngIf="this.images.length === 0 && !this.requesting">
    {{ "search.no_multimedia" | translate }}
  </h4>

  <div
    class="multimedia-image-list mat-typography"
    infinite-scroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="500"
    (scrolled)="onScroll()">
    <app-multimedia-viewer
      (multimedia_item_request)="this.navigator.navigate_to_multimedia_item($event)"
      [images]="this.images">
    </app-multimedia-viewer>
  </div>
</div>

export class VernacularName {
  name = "—";

  constructor(content: any) {
    this.set(content);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param content with JSON data received from the server
   * @author Luuk
   */
  public set(content: any) {
    this.name = "name" in content ? content["name"] : "";
  }
}

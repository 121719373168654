/**
 * This component accepts images of the Multimedia type and visualises them on screen.
 * @Input images (Multimedia)
 * @Author Luuk
 */

// Library imports
import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";

// Model imports
import { Multimedia } from "@bioportal/models/Multimedia";

// Service imports
import { HelperService } from "@bioportal/services/helper.service";
import { UtilityService } from "@bioportal/services/utility.service";

@Component({
  selector: "app-multimedia-viewer",
  templateUrl: "./multimedia-viewer.component.html",
  styleUrls: ["./multimedia-viewer.component.scss"],
})
export class MultimediaViewerComponent implements OnChanges {
  @Input() images: Multimedia[];
  @Output() multimedia_item_request = new EventEmitter<string>();

  constructor(
    private helper: HelperService,
    protected utility: UtilityService,
  ) {}

  ngOnChanges(): void {
    this.helper.multimedia_list = this.images;
  }

  /**
   * Navigates the page to the clicked multimedia item
   * @param identifier (string)
   * @author Luuk
   */
  protected request_multimedia_item(identifier: string): void {
    this.multimedia_item_request.emit(identifier);
  }
}

import { Component, OnInit } from "@angular/core";
import { ChartType } from "angular-google-charts";
import { ActivatedRoute } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "@bioportal/services/utility.service";
import { DrupalService } from "@bioportal/services/api/drupal.service";
import { NbaService } from "@bioportal/services/api/nba.service";

import { environment } from "@src/environments/environment";
import { DatePipe } from "@angular/common";

export interface Province {
  name?: string;
  count?: string;
  percentage?: number;
}

export interface Record {
  name?: string;
  collectionType?: string;
  count?: number;
  values?: any[];
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(
    protected utility: UtilityService,
    protected translate: TranslateService,
    private route: ActivatedRoute,
    private drupal: DrupalService,
    private nba: NbaService,
    public datePipe: DatePipe,
  ) {}

  // First row
  update_dates: any = {
    brahms_specimen: "",
    crs_specimen: "",
    nsr_taxon: "",
    col_taxon: "",
    dcsr_taxon: "",
  };

  update_date_table = [
    { label: "dashboard.brahms_specimen", property: "brahms_specimen" },
    { label: "dashboard.crs_specimen", property: "crs_specimen" },
    { label: "dashboard.nsr_taxon", property: "nsr_taxon" },
    { label: "dashboard.col_taxon", property: "col_taxon" },
    { label: "dashboard.dcsr_taxon", property: "dcsr_taxon" },
  ];

  // First row
  specimen_total_count: number;
  specimen_number_of_records: number;
  specimen_storage_units: number;

  taxon_total_count: number;
  multimedia_total_count: number;

  // Second row
  collection_categories_by_specimen_count: any = [];
  specimen_pie = {
    type: ChartType.PieChart,
    data: [],
    columnNames: ["Browser", "Percentage"],
    options: {
      legend: "none",
    },
  };

  // Third row
  taxon_per_rank: any[] = [];
  unique_scientific_names_with_specimens: number;
  taxonomic_accepted_names: number;
  unique_synonyms: number;
  unique_common_names: number;

  // Fourth row
  province_list: any[] = [];
  province_chart_data: any;
  province_chart = {
    type: ChartType.GeoChart,
    data: [],
    options: {
      region: "NL",
      resolution: "provinces",
      colorAxis: {
        minValue: 0,
        colors: ["#cfffcb", "#00a050"],
      },
    },
  };

  // Fifth row
  country_list: any = [];
  country_chart = {
    type: ChartType.GeoChart,
    data: [],
    options: {
      colorAxis: {
        minValue: 0,
        colors: ["#cfffcb", "#65de8e", "#33cd6d", "#10b060", "#00a050"],
      },
    },
  };

  // Sixth row
  typestatus_records_per_collection: any[] = [];

  // Seventh row
  top_collected_subspecies: any = [];
  top_collectors: any = [];

  protected locale: string;

  /**
   * Puts the json blob with dashboard data in the corresponding class fields
   * @param locale used to format the string
   */
  private retrieve_dashboard_data(locale: string): void {
    this.drupal.query_dashboard_data().subscribe({
      next: (data) => {
        this.specimen_total_count = data["specimen_total_count"].toLocaleString(locale);
        this.specimen_number_of_records = data["specimen_number_of_records"].toLocaleString(locale);
        this.specimen_storage_units = data["specimen_bewaareenheden"].toLocaleString(locale);

        this.taxon_total_count = data["taxon_total_count"].toLocaleString(locale);
        this.multimedia_total_count = data["multimedia_total_count"].toLocaleString(locale);

        this.collection_categories_by_specimen_count = this.convert_json_to_chart_data(data["collection_categories"]);
        this.specimen_pie.data = this.collection_categories_by_specimen_count;

        this.taxon_per_rank = this.convert_json_to_chart_data(data["taxon_per_rank"]);
        this.unique_scientific_names_with_specimens =
          data["unique_scientific_names_with_specimens"].toLocaleString(locale);
        this.taxonomic_accepted_names = data["taxonomic_accepted_names"].toLocaleString(locale);
        this.unique_common_names = data["unique_common_names"].toLocaleString(locale);
        this.unique_synonyms = data["unique_synonyms"].toLocaleString(locale);

        [this.province_chart_data, this.province_list] = this.create_province_list(data["specimens_per_province"]);
        this.province_chart.data = this.province_chart_data;

        this.country_list = this.convert_json_to_chart_data(data["specimens_per_country"]);
        this.country_chart.data = this.country_list;
        this.country_list = this.country_list.slice(0, 10);

        data["typestatus_records_per_collection"].forEach((value: any) => {
          const record: Record = {};
          record.collectionType = value["collectionType"];
          record.count = value["count"];
          record.values = value["values"].slice(0, 5);
          this.typestatus_records_per_collection.push(record);
        });
        this.typestatus_records_per_collection = this.typestatus_records_per_collection.slice(0, 6);

        data["top_collectors"].forEach((value: any) => {
          const record: Record = {};
          record.name = value["gatheringEvent.gatheringPersons.fullName"];
          record.count = value["count"];
          record.values = value["values"];
          this.top_collectors.push(record);
        });
        this.top_collectors = this.top_collectors.slice(0, 15);

        this.top_collected_subspecies = this.convert_json_to_chart_data(data["top_collected_subspecies"]);
        this.top_collected_subspecies = this.top_collected_subspecies.slice(0, 20);
      },
    });
    this.nba.get_import_files_updates().subscribe({
      next: (data) => {
        // traverse the json blob and put the data in the corresponding class fields
        for (const item in data) {
          // check if item is in update_dates
          if (item in this.update_dates) {
            this.update_dates[item] = data[item]["harvest_date"];
          }
        }
      },
    });
  }

  ngOnInit(): void {
    const params = this.route.snapshot.params;
    this.locale = params["language"] ? params["language"] : environment.defaultLanguage;
    this.retrieve_dashboard_data(this.locale);
    this.utility.update_meta_tags("Dashboard");
  }

  /**
   * Creates a list of provinces from the given API data. Also generates data readable for google charts
   * @param data from json blob
   * @returns list with province data
   * @returns list with province chart data
   */
  private create_province_list(data: any): [any, Province[]] {
    const province_list: any[] = [];
    const province_list_chart_data: any[] = [];
    let i = 0;
    for (const item in data) {
      if (i >= 12) {
        break;
      }
      const province: Province = {};
      province.name = item;
      province.count = data[item].count;
      province.percentage = data[item].percentage;
      province_list.push(province);
      province_list_chart_data.push([province.name, Number(province.count)]);
      i++;
    }
    return [province_list_chart_data, province_list];
  }

  /**
   * Converts the JSON objects to Google Chart data
   * @param data
   * @returns
   */
  private convert_json_to_chart_data(data: any): any[] {
    const result_list: any[] = [];

    Object.entries(data).forEach((key: any) => {
      let count = String(key[1]);
      count = count.replaceAll(".", "");
      result_list.push([key[0], Number(count)]);
    });

    return result_list;
  }

  /**
   * Routes from the type status records cell to the result/specimen page given the collection and type.
   * @param collection name of the collection
   * @param collection_type
   */
  protected route_collection_type(collection: string, collection_type: string): void {
    this.utility.navigate_with_params("result/specimen", {
      collection_name: collection,
      type_status: collection_type,
      operator: "AND",
    });
  }

  /**
   * Routes from the type status records cell to the result/specimen page given the collection and type.
   * @param collection name of the collection
   * @param collection_type
   */
  protected route_scientific_name(scientific_name: string): void {
    this.utility.navigate_with_params("result/specimen", { scientific_name: scientific_name.replaceAll(" ", "_") });
  }

  /**
   * Routes from the top collector cell to the corresponding collection by this collector
   * @param collector name
   * @param collection name
   */
  protected route_top_collector(collector: string, collection_name: string): void {
    this.utility.navigate_with_params("result/specimen", {
      collector: collector,
      collection_name: collection_name,
      operator: "AND",
    });
  }
}

/**
 * Typescript object to create a query spec and handle its processing
 */
export class QuerySpec {
  private _conditions: object[] = [];
  private _size: number;
  private _operator: string;

  constructor(query_spec?: Partial<QuerySpec>) {
    // Allow the partial initialisation of a query spec object
    Object.assign(this, query_spec);
  }

  set size(value: number) {
    this._size = value;
  }

  public add_condition(condition: object) {
    this._conditions.push(condition);
  }

  public build(): any {
    return {
      conditions: this._conditions,
      logicalOperator: this._operator,
      size: this._size,
    };
  }
}

/**
 * Typescript object to handle all the incoming taxon JSON data
 */
export class Taxon {
  scientific_name = "";
  scientific_name_group = "";
  authorship_verbatim: string;
  vernaculars: any[] = [];
  record_URI = "";
  source_system_code = "—";
  source_system_name = "—";
  synonyms: any[] = [];
  descriptions: any[] = [];
  classifications: any[] = [];

  description_nl = "";
  description_en = "";

  worldcat_link: string;
  bhl_link: string;

  has_classification = false;
  has_description = false;
  has_dutch_description = false;
  has_english_description = false;
  has_synonyms = false;
  has_vernaculars = false;

  constructor(taxon?: Partial<Taxon>) {
    // Allow the partial initialisation of a taxon object
    Object.assign(this, taxon);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param taxon with JSON data received from the server
   * @author Luuk
   */
  public set(taxon: any) {
    this.scientific_name = "acceptedName" in taxon ? taxon["acceptedName"]["fullScientificName"] : "";
    this.scientific_name_group = "acceptedName" in taxon ? taxon["acceptedName"]["scientificNameGroup"] : "";
    this.authorship_verbatim = "acceptedName" in taxon ? taxon["acceptedName"]["authorshipVerbatim"] : "";

    this.record_URI = "recordURI" in taxon ? taxon["recordURI"] : "";

    if ("sourceSystem" in taxon) {
      this.source_system_code = taxon["sourceSystem"]["code"];
      this.source_system_name = taxon["sourceSystem"]["name"];
    }

    if ("synonyms" in taxon) {
      this.synonyms = taxon["synonyms"];
      this.has_synonyms = true;
    }

    if ("descriptions" in taxon) {
      this.descriptions = taxon["descriptions"];

      const dutch_descriptions = this.descriptions.filter((object: any) => object.category == "Samenvatting");
      const english_descriptions = this.descriptions.filter((object: any) => object.category == "Summary");

      if (dutch_descriptions?.length) {
        this.has_dutch_description = true;
        this.description_nl = dutch_descriptions[0]["description"];
      }

      if (english_descriptions?.length) {
        this.has_english_description = true;
        this.description_en = english_descriptions[0]["description"];
      }

      if (this.has_dutch_description || this.has_english_description) {
        this.has_description = true;
      }
    }

    if ("systemClassification" in taxon) {
      this.classifications = taxon["systemClassification"];
      this.has_classification = true;
    }

    if ("vernacularNames" in taxon) {
      this.vernaculars = taxon["vernacularNames"];
      this.has_vernaculars = true;
    }

    // Delete duplicate vernaculars
    this.vernaculars = this.vernaculars.filter(
      (value, index, self) => index === self.findIndex((t) => t.language === value.language && t.name === value.name),
    );
    // Sort vernaculars on language
    this.vernaculars.sort(function (a, b) {
      const textA = a.language;
      const textB = b.language;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    this.worldcat_link = `https://www.worldcat.org/search?q=ti%3A%22${this.scientific_name_group}%22`;
    this.bhl_link = `https://www.biodiversitylibrary.org/search?searchTerm=${this.scientific_name_group}&stype=F#/titles`;
  }
}

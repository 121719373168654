// Library imports
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@src/environments/environment";

import menuData from "./menus.json";

import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DrupalService {
  isBrowser = false;
  webserver = "";

  // create a map of maps to store the menu keys for specific languages
  menus = new Map<string, Map<string, string>>();

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.webserver =
      this.isBrowser && !environment.production ? window.location.origin : environment.dockerDrupalWebserver;

    for (const item of menuData) {
      const menu = new Map<string, string>();
      menu.set("nl", item.nl);
      menu.set("en", item.en);
      this.menus.set(item.key, menu);
    }
  }

  /**
   * Get the menu key for the requested menu
   * @param menuName
   * @param language
   * @private
   */
  private get_menu(menuName: string, language: string): string | undefined {
    const menu = this.menus.get(menuName);
    if (!menu) {
      return undefined;
    }
    return menu.get(language);
  }

  /**
   * Runs the given query on the drupal api
   * @param query that is to be send to the api
   * @returns observable that will watch incoming data from the api
   * @author Luuk
   */
  public run_query(query: string): Observable<any> {
    return this.http.get<any>(query);
  }

  /**
   * Queries the drupal api for all featured content
   * @returns observable that will watch incoming data from the api
   */
  public query_featured_content(): Observable<any> {
    const query: string = this.webserver + "/jsonapi/node/naturalis_featured_content";

    return this.run_query(query);
  }

  /**
   * Retrieves the dashboard data
   * @returns observable
   */
  public query_dashboard_data(): Observable<any> {
    const query: string = this.webserver + "/json_dashboard/";
    return this.run_query(query);
  }

  /**
   * Queries the drupal api for all page content
   * @returns observable that will watch incoming data from the api
   */
  public query_page_content(): Observable<any> {
    const query: string = this.webserver + "/jsonapi/node/page";

    return this.run_query(query);
  }

  /**
   * Queries the drupal api for menu items
   * @param language
   * @param menuName
   */
  public query_menu_content(menuName: string): Observable<any> {
    const language = this.translate.currentLang ? this.translate.currentLang : environment.defaultLanguage;
    const query: string = this.webserver + "/jsonapi/menu_items/" + this.get_menu(menuName, language);

    return this.run_query(query);
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "simple_filter" })
// Filters the locality of locations
export class FilterPipe implements PipeTransform {
  transform(value: any, input: any): any {
    if (input) {
      // To ensure case insensitivity, both value and input are made uppercase.
      return value.filter((val: any) => val.localized.toUpperCase().indexOf(input.toUpperCase()) >= 0);
    } else {
      return value;
    }
  }
}

/**
 * This service handles requests to the NBA. It can create queries and send them to
 * the NBA. Retrieved data is formatted and given to the Angular components using the
 * rxjs BehaviorSubjects. In short: a component requests the nba for data. Upon completion,
 * the nba alerts all applicable subscribers of this new data.
 *
 * TODO: create a new service called querybuilder to separate nba communication
 *       and the creation of nba queries.
 */

// Library imports
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Meta } from "@angular/platform-browser";
import { Params } from "@angular/router";

// JSON data objects imports
import data_source_systems from "./data_source_systems.json";
import highlight_queries from "./highlights_queries.json";
import nba_field_mapping from "./nba_field_mapping.json";
import boost_values from "./boost_values.json";

// Service imports
import { UtilityService } from "@bioportal/services/utility.service";
import { environment } from "@src/environments/environment";
import { HelperService } from "@bioportal/services/helper.service";
import { StorageService } from "@bioportal/services/storage.service";
import { QueryBuilderService } from "@bioportal/services/query-builder.service";

// Model imports
import { Search_data } from "@bioportal/models/Search_data";
import { Specimen } from "@bioportal/models/Specimen";
import { Taxon } from "@bioportal/models/Taxon";
import { Multimedia } from "@bioportal/models/Multimedia";

export interface nba_result {
  totalSize: number;
  resultSet: Array<any>;
}

@Injectable({
  providedIn: "root",
})
export class NbaService {
  public query_spec_list: any[] = [];

  highlight_queries: any = highlight_queries;
  private license_numbers: string[] = ["1.0", "2.0", "2.5", "3.0", "4.0"];
  params: Params;

  // To keep track of the match operators that cannot all default to CONTAINS.
  field_match_operators: any = {
    basic_term: "EQUALS_IC",
    scientific_name: "CONTAINS",
    common_name: "CONTAINS",
    family: "CONTAINS",
    genus: "CONTAINS",
    epithet: "CONTAINS",
    registration_number: "EQUALS_IC",
    source: "EQUALS_IC",
    collection_name: "EQUALS_IC",
    type_status: "EQUALS_IC",
    type_material: "EQUALS_IC",
    locality: "MATCHES",
    phase_stage: "EQUALS_IC",
    sex: "EQUALS_IC",
    collector: "CONTAINS",
    collector_field_number: "CONTAINS",
    kingdom: "CONTAINS",
    phylum: "CONTAINS",
    class: "CONTAINS",
    order: "CONTAINS",
    subgenus: "CONTAINS",
    infraspecific_name: "EQUALS_IC",
    old_barcodes: "EQUALS_IC",
    chronostratigraphy: "EQUALS_IC",
    lithostratigraphy: "EQUALS_IC",
    biostratigraphy: "EQUALS_IC",
    license: "EQUALS_IC",
  };

  // Helper object to save the current result size. Used to show totalSize on overview and list pages
  current_result_size: any = {
    specimens: 0,
    taxa: 0,
    multimedia: 0,
  };

  // Keeps track whether the current query was applicable to each database
  query_applicable_on: any = {
    specimens: true,
    taxa: true,
    multimedia: true,
  };

  data: any = {
    specimens: this.create_nba_result_interface(),
    taxa: this.create_nba_result_interface(),
    multimedia: this.create_nba_result_interface(),
  };

  // To keep track of each individual spinner
  loading_data: any = {
    specimens: false,
    taxa: false,
    multimedia: false,
  };

  // Object to store API keys per field
  nba_field_mapping: any = nba_field_mapping;
  data_source_systems: any = data_source_systems;
  boost_values: any = boost_values;

  // Template for a query spec. Note that each query condition will be pushed
  // to the conditions list within this object.
  query_spec_template: any = {
    conditions: [],
  };

  // Object to save queries
  saved_query_specs: any = {
    specimens: false,
    taxa: false,
    multimedia: false,
  };

  // List to save whether taxon for scientific name exists.
  // Will contain objects like {name: "helianthus", exists: true}.
  taxon_exists: any[] = [];

  // Template for a single query condition
  query_condition_template: any = {
    field: "",
    operator: "",
    value: "",
    or: [],
    and: [],
  };

  // Template for location querying. The escaping is necessary for the nba api
  location_condition_template: any = {
    field: "",
    operator: "IN",
    value: '{"type":"MultiPolygon","coordinates":[]}',
    and: [],
  };

  // Template for retrieving multimedia linked to specimen
  linked_media_template: any = {
    conditions: [
      {
        field: "associatedSpecimenReference",
        operator: "EQUALS",
        value: "",
        and: [this.data_source_systems],
      },
    ],
    constantScore: true,
    size: 12,
  };

  linked_assemblage_template: any = {
    conditions: [
      {
        field: "assemblageID",
        operator: "EQUALS",
        value: "",
        and: [this.data_source_systems],
      },
    ],
    constantScore: true,
  };

  isBrowser = false;

  urls: any = {
    specimens: environment.nbaApiUrl + "specimen/query/?_querySpec=",
    taxa: environment.nbaApiUrl + "taxon/query/?_querySpec=",
    multimedia: environment.nbaApiUrl + "multimedia/query/?_querySpec=",
    geo: environment.nbaApiUrl + "geo/query/?_querySpec=",
    distinct_values: {
      specimens: environment.nbaApiUrl + "specimen/getDistinctValues/{field}?_querySpec=",
      multimedia: environment.nbaApiUrl + "multimedia/getDistinctValues/{field}?_querySpec=",
    },
    dwca_taxon_datasets: environment.nbaApiUrl + "taxon/dwca/getDataSetNames",
    dwca_specimen_datasets: environment.nbaApiUrl + "specimen/dwca/getDataSetNames",
    import_files_updates: environment.nbaApiUrl + "import-files",
  };

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    private meta: Meta,
    private http: HttpClient,
    private helper: HelperService,
    private storage: StorageService,
    private query_builder: QueryBuilderService,
    protected utility: UtilityService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public get(query: string): Observable<any> {
    return this.http.get<any>(query);
  }

  /**
   * Retrieves distinct values for the given field and the corresponding endpoint
   * @param field (string)
   * @param endpoint (string)
   * @return list (Observable)
   * @author Luuk
   */
  public get_distinct_values(field: string, endpoint: string): Observable<string[]> {
    const query_spec = this.query_builder.distinct_values();
    const query = this.urls["distinct_values"][endpoint].replace("{field}", field) + this.encode_query_spec(query_spec);
    return new Observable((observer) => {
      this.get(query).subscribe({
        next: (data) => {
          observer.next(data);
          observer.complete();
        },
      });
    });
  }

  public create_nba_result_interface(result_set?: Array<any>, total_size?: number): nba_result {
    return {
      resultSet: result_set != null ? result_set : [],
      totalSize: total_size != null ? total_size : -1,
    } as nba_result;
  }

  public create_query(query_spec: object, query_type: string): string {
    return this.urls[query_type] + this.encode_query_spec(query_spec);
  }

  /**
   * Converts the query spec object into a GET compatible string
   * @param object containing the query spec
   * @return string representation of the query spec
   * @author Luuk
   */
  private encode_query_spec(query_spec: object): string {
    return encodeURIComponent(JSON.stringify(query_spec));
  }

  /**
   * Create a simple query condition
   * @param field in which we have to search for our value
   * @param operator whether we want the field to CONTAIN, MATCH, etc the value
   * @param value wich we want to look for the the field given the operator
   * @param category which category we want to create the condition for
   * @returns object representing the created query
   * @author Luuk
   */
  public create_query_condition(field: string, operator: string, value: string, category?: string) {
    const query_condition = structuredClone(this.query_condition_template);
    query_condition["field"] = field;
    query_condition["operator"] = operator;
    query_condition["value"] = value;
    if (category) {
      const boost = this.get_boost(field, category);
      if (boost) {
        query_condition["boost"] = boost;
      }
    }
    return query_condition;
  }

  /**
   * Retrieves the boost given the nba field mapping and its category
   * @param field
   * @param category
   * @returns number representing the boost
   * @author Luuk
   */
  private get_boost(field: string, category: string): number {
    return this.boost_values[category][field];
  }

  /**
   * Creates a new query spec given the definied template
   * @returns object representing a query spec
   * @author Luuk
   */
  public new_query_spec(): any {
    return structuredClone(this.query_spec_template);
  }

  /**
   * Given the database and the form, we can prune some of the categories that are in the form, but
   * not in the selected database. For example, if taxa and specimens are selected, the form field
   * will show lithostratigraphy, which is not a field of taxa. We will remove the field then from
   * taxa using this function
   * @param form with fields that can be pruned
   * @param category functions as a key to see if form items can be pruned
   * @returns pruned form values
   * @author Luuk
   */
  private filter_form_on_database(form: object, category: string): any {
    const object: any = structuredClone(form);
    for (const item in object) {
      // First, check whether the item exists in the category, otherwise dont bother searching
      if (!(item in this.nba_field_mapping[category])) {
        delete object[item];
      }
    }
    return object;
  }

  /**
   * Checks whether the given search fields are applicable on the given category
   * @param search_fields that are listed
   * @param category
   * @returns boolean
   */
  private check_search_field_category_applicability(search_data: Search_data, category: string): boolean {
    if (search_data.using_basic_term) {
      return true;
    }
    for (const item in search_data.search_fields) {
      // First, check whether the item exists in the category, otherwise dont bother searching
      if (!(item in this.nba_field_mapping[category])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns a list of license values with their version number appended
   * @param values of licenses to be appended
   * @author Luuk
   */
  private set_license_search_values(values: string[]): string[] {
    const license_list: string[] = [];
    values.forEach((license) => {
      license_list.push(license);
      this.license_numbers.forEach((number) => {
        license_list.push(`${license} ${number}`);
      });
    });
    return license_list;
  }

  /**
   * Creates a condition soley for the source. This will be appended to every AND field for every condition in a query spec
   * @param category to be able to lookup API keys
   * @param values data added to search field
   * @author Luuk
   */
  private create_source_condition(category: string, values: any) {
    let condition: any = [];
    // Loop through all key words in the search field
    for (const i in values) {
      const keyword = values[i];
      // Now for each keyword, create an entry for the keyword, search field and api key
      // For the first keyword in the loop, we create a condition, next we append to the 'or' field
      if (i == "0") {
        condition = this.create_keyword_condition(category, "source", keyword);
        delete condition["and"];
      } else {
        const nested_condition = this.create_keyword_condition(category, "source", keyword);
        delete nested_condition["and"];
        condition["or"].push(nested_condition);
      }
    }
    return condition;
  }

  /**
   * For the given search values for a single search term, this function creates a single query spec condition.
   * @param field like genus, family, containing user given data
   * @param values data added to search field
   * @param category to be able to lookup API keys
   * @param source condition object that shows which sources are to be looked through
   * @author Luuk
   */
  private create_search_condition(field: string, values: any, category: string) {
    let condition: any = [];

    if (field == "license") {
      values = this.set_license_search_values(values);
    }

    // Loop through all key words in the search field
    for (const i in values) {
      const keyword = values[i];
      // Now for each keyword, create an entry for the keyword, search field and api key
      // For the first keyword in the loop, we create a condition, next we append to the 'or' field
      if (i == "0") {
        condition = this.create_keyword_condition(category, field, keyword);
      } else {
        // Always push to OR within field conditions.
        condition["or"].push(this.create_keyword_condition(category, field, keyword));
      }
    }
    return condition;
  }

  /**
   * Given a term in a field, this function creates a condition that searches each
   * API key for the given term. For example, if we provide 'canis' as a genus,
   * this function will make sure that all api keys for genus are combined into one
   * single condition using the 'or' capabilities.
   * @param category allows us to find relevant api keys for category like multimedia
   * @param search_field allows us to find  relevant api keys for field like genus
   * @param word the value which has to be given to the condition
   * @param source condition object that shows which sources are to be looked through
   * @returns condition object that allows querying over multiple
   */
  private create_keyword_condition(category: string, search_field: string, word: string): any {
    // Now for each api lookup, create a query condition
    let first = true;
    let condition: any = {};

    for (const api_key in this.nba_field_mapping[category][search_field]) {
      let operator = this.field_match_operators[search_field];
      if (word.length > 15) {
        operator = "STARTS_WITH_IC";
      }

      // If we have multiple lookup keys, we need to put these in one nested condition, in which the first is
      // a normal condition and the subsequent ones are conditions in the 'or' list of the first.
      if (first) {
        first = false;
        // For the first entry, create a normal query condition
        condition = this.create_query_condition(
          this.nba_field_mapping[category][search_field][api_key], // Retrieve the api lookup call for the current item
          operator,
          word, // Provide the value which we are searching for
          category,
        );
        condition = this.add_equals_boost(condition);
      } else {
        // For each subsequent condition, put the new condition in the 'or' field of the first
        //const nestable_query_spec = this.find_condition_to_nest_in(condition_list, saved_primary_condition_field);
        let nested_condition = this.create_query_condition(
          this.nba_field_mapping[category][search_field][api_key], // Retrieve the api lookup call for the current item
          operator,
          word, // Provide the value which we are searching for
          category,
        );
        nested_condition = this.add_equals_boost(nested_condition);
        condition["or"].push(nested_condition);
      }
    }
    return condition;
  }

  /**
   * Retrieves the complete query for the provided collection. It first retrieves the query_spec template,
   * then provides the name of the collection at the correct positions. Lastly, it creates the query and returns it.
   * @param source we want to retrieve the highlight query for
   * @param collection_id name of the collection for instanc 'en_tibi'
   * @returns query ready to be send to the server
   * @author Luuk
   * @TODO: replace hardcoded array locations by .find or .locate functionality
   */
  public get_collection_queryspec(source: string, collection: string): any {
    const query_spec = structuredClone(this.highlight_queries[source]);
    query_spec["conditions"][0]["value"] = collection;
    query_spec["conditions"][0]["or"][0]["value"] = collection;
    return query_spec;
  }

  /**
   * Creates a query spec that retrieves the next set of items.
   * @param object query_spec
   * @param number of items we already retrieved (from)
   * @param number of next number of items we want to retrieve (to)
   * @param object updated query_spec
   */
  public page_query_spec(query_spec: any, page: number, items_per_page: number): any {
    query_spec["from"] = page * items_per_page;
    query_spec["size"] = items_per_page;
    return query_spec;
  }

  public create_area_condition(area: string, category: string): object {
    let field = "";
    // The exact field name differs by one character for the multimedia and specimens databases
    if (category == "multimedia") {
      //TODO: will we put this somewhere? Maybe in the nba_field_mapping.json?
      field = "gatheringEvents.siteCoordinates.geoShape";
    } else if (category == "specimens") {
      field = "gatheringEvent.siteCoordinates.geoShape";
    }
    const query_spec = this.create_query_condition(field, "IN", area);
    delete query_spec["and"];
    return query_spec;
  }

  public create_polygon_condition(polygon: any[], category: string): object {
    const location_condition_template = structuredClone(this.location_condition_template);
    // The exact field name differs by one character for the multimedia and specimens databases
    if (category == "multimedia") {
      //TODO: will we put this somewhere? Maybe in the nba_field_mapping.json?
      location_condition_template["field"] = "gatheringEvents.siteCoordinates.geoShape";
    } else if (category == "specimens") {
      location_condition_template["field"] = "gatheringEvent.siteCoordinates.geoShape";
    }
    // The nba requires the value field of a polygon to be a string, in which the quotation
    // markings are escaped. Because of this, we replace the empty coordinates list in this string
    // with one that represents the polygon we provide. It would be nice if the nba could fix this
    // weird workaround. NB: the polygon, which is a list of lists, has to be put in a list.
    const polygon_string = JSON.stringify([[polygon]]);
    location_condition_template["value"] = location_condition_template["value"].replace("[]", polygon_string);
    delete location_condition_template["and"];
    return location_condition_template;
  }

  public clear_data_object(category: string): void {
    this.data[category] = this.create_nba_result_interface();
  }

  /**
   * Converts the given search_data object to the correct query_spec
   * @param search_data (Search_data)
   * @param endpoint (string)
   * @return query_spec (object)
   * @author Luuk
   */
  public convert_search_data_to_query_spec(search_data: Search_data, endpoint: string) {
    return this.create_query_spec(search_data, endpoint);
  }

  /**
   * Runs the given query_spec on the given NBA endpoint. Returns the data.
   * @param query_spec (object)
   * @param endpoint (string)
   * @param identifier (string) we use to save and load data
   * @returns Observable (nba data)
   * @author Luuk
   */
  public get_data(query_spec: any, endpoint: string, check_storage?: boolean): Observable<any> {
    const query = this.create_query(query_spec, endpoint);

    //TODO: it turns out that integrating storage in this function does not work. We need to
    //separate the NBA from the storage as the same identifier for everything will break either
    //the multimedia list or the assemblage pages.
    if (check_storage === undefined) {
      check_storage = true;
    }

    return new Observable((observer) => {
      // Check if the storage already contains the requested data
      if (check_storage && this.storage.has(endpoint, JSON.stringify(query_spec))) {
        observer.next(this.storage.get(endpoint, JSON.stringify(query_spec)));
        observer.complete();
      } else {
        // If no saved data was found, we request the NBA for it
        this.loading_data[endpoint] = true;
        this.get(query).subscribe({
          next: (data) => {
            this.loading_data[endpoint] = false;
            //TODO: turn the resultset into a list of endpoint items
            let formatted_data = this.create_nba_result_interface(data["resultSet"], data["totalSize"]);
            formatted_data = {
              ...formatted_data,
              ...{ category: endpoint },
              ...{ query: query },
              ...{ result_set: this.set_items(data["resultSet"], endpoint) },
            };
            this.data[endpoint] = this.create_nba_result_interface(data.resultSet, data.totalSize);
            // Save this data collection to our data object
            this.storage.save(endpoint, formatted_data, JSON.stringify(query_spec));
            // Save the query_spec for the debug query printer
            this.query_spec_list.push({ endpoint: endpoint, query_spec: query_spec });
            observer.next(formatted_data);
            observer.complete();
          },
        });
      }
    });
  }

  /**
   * Parses the NBA resultset into Typescript models given the endpoint
   * @param result_set (object)
   * @param endpoint (string)
   * @return object
   */
  private set_items(result_set: any, endpoint: string) {
    const return_list: any[] = [];
    switch (endpoint) {
      case environment.multimediaDenominator: {
        result_set.forEach((result: any) => {
          const image = new Multimedia();
          image.set(result.item);
          return_list.push(image);
        });
        if (return_list.length > 0) {
          this.meta.updateTag({ property: "og:image", content: return_list[0].medium_image() });
        }
        return return_list;
      }
      case environment.specimensDenominator: {
        result_set.forEach((result: any) => {
          const specimen = new Specimen();
          specimen.set(result.item);
          return_list.push(specimen);
        });
        return return_list;
      }
      case environment.taxaDenominator: {
        result_set.forEach((result: any) => {
          const taxon = new Taxon();
          taxon.set(result.item);
          return_list.push(taxon);
        });
        return return_list;
      }
      case environment.geoDenominator: {
        return return_list;
      }
      default: {
        console.error("Unknown endpoint.");
        return [];
      }
    }
  }

  public get_dwca_sets(category: string): Observable<any> {
    return this.get(this.urls[category]);
  }

  public get_import_files_updates(): Observable<any> {
    return this.get(this.urls["import_files_updates"]);
  }

  /**
   * Checks if a taxon exists for the given scientific name. The result will be written to
   * this.taxon_exists for other components to read.
   * @param string of taxon name we want to check the existence of in the nba
   * @author Luuk
   */
  public request_taxon_existence(taxon_scientific_name: string): void {
    // Check if taxon is already requested and in our list. If so, we do not request the NBA.
    const target = this.taxon_exists.findIndex((obj: any) => obj.taxon === taxon_scientific_name);
    // If not, request NBA
    if (target == -1) {
      const search_data = { endpoint: taxon_scientific_name, size: environment.defaultQuerySize };
      const query_spec = this.create_taxon_item_query_spec(search_data);
      const query = this.create_query(query_spec, environment.taxaDenominator);
      this.get(query).subscribe({
        next: (data) => {
          const formatted_data = this.create_nba_result_interface(data["resultSet"], data["totalSize"]);
          const taxon_exists = formatted_data.totalSize > 0;
          this.taxon_exists.push({ taxon: taxon_scientific_name, exists: taxon_exists });
        },
      });
    }
  }

  /**
   * Returns a boolean whether a taxon exists in the nba
   * @param string of taxon
   * @return boolean whether it exists
   * @author Luuk
   */
  public get_taxon_existence(taxon_scientific_name: string): boolean {
    const target = this.taxon_exists.findIndex((obj: any) => obj.taxon === taxon_scientific_name);
    if (target >= 0) {
      return this.taxon_exists[target].exists;
    } else {
      return false;
    }
  }

  //TODO: needs to be ported to new category + format key.
  public create_query_spec(search_data: Search_data, category: string) {
    let query_spec: any = {};
    if (search_data.page_type == "highlights") {
      query_spec = this.get_collection_queryspec(category, search_data.search_term);
      query_spec["size"] = search_data.size;
    } else if (search_data.page_type == "url_query") {
      query_spec = this.create_url_query_spec(search_data, category);
    } else if (search_data.page_type == "taxa") {
      query_spec = this.create_taxa_query_spec(search_data);
    } else if (search_data.page_type == "specimen-item") {
      query_spec = this.create_specimen_item_query_spec(search_data);
    } else if (search_data.page_type == "taxon-item") {
      query_spec = this.create_taxon_item_query_spec(search_data);
    } else if (search_data.page_type == "multimedia-item") {
      query_spec = this.create_multimedia_item_query_spec(search_data);
    } else {
      console.error("Unknown page type");
    }
    // Save the queryspec for later use
    this.saved_query_specs[category] = query_spec;
    return query_spec;
  }

  /**
   * Adds boost for a search_term equaling a found item in the nba
   * @param search_condition to receive an additional boost condition
   */
  private add_equals_boost(search_condition: any): any {
    if (search_condition["field"] == "license") {
      return search_condition;
    }
    if (search_condition["operator"] != "EQUALS_IC") {
      const boost_condition = this.create_query_condition(
        search_condition["field"],
        "EQUALS_IC",
        search_condition["value"],
      );
      search_condition["or"].push(boost_condition);
      boost_condition["boost"] = 5;
    } else {
      search_condition["boost"] = 5;
    }
    return search_condition;
  }

  /**
   * Recursively pushes values to all AND fields in the given condition. Used for supplying each condition with
   * a geo condition and source condition
   * @param o object that is recursively search for AND fields
   * @param value that is to be pushed in the AND field
   * @author Luuk
   */
  private push_value_to_and_condition(o: any, value: any) {
    Object.keys(o).forEach((key) => {
      // forEach key in the object
      if (key === "and") o[key].push(value); // is the key AND? push the condition to this field
      if (typeof o[key] === "object" && o[key] !== null) {
        // if the key is an object (call the function recursively)
        this.push_value_to_and_condition(o[key], value);
      }
    });
  }

  private create_url_query_spec(search_data: Search_data, category: string): any {
    let source: any = {};
    let geo: any = {};

    const query_spec = this.new_query_spec();

    this.query_applicable_on[category] = this.check_search_field_category_applicability(search_data, category);
    const search_fields = this.filter_form_on_database(search_data.search_fields, category);

    // Ignore the source field when using the basic term
    if (search_data.using_basic_term) {
      delete search_fields["source"];
    }
    // Check if we have a custom source field. If so, we create a condition and will push it to every
    // AND field of every condition once they are created
    if ("source" in search_fields) {
      source = this.create_source_condition(category, search_fields.source);
      delete search_fields["source"];
    } else {
      // If source is not provided, we search through the bioportal data systems (NSR, DCSR, COL, CRS, BRAHMS)
      source = this.data_source_systems;
    }
    // Push the geo data if any has been provided. Like the source conditions, this will be pushed to every AND field
    if (category != "taxa") {
      if (!this.utility.is_empty_array(search_data.polygon)) {
        geo = this.create_polygon_condition(search_data.polygon, category);
      } else if (search_data.area != "") {
        geo = this.create_area_condition(search_data.area, category);
      }
    }

    const conditions_list: any[] = [];
    // Loop through each form field and create an object with all api calls
    for (const [key, value] of Object.entries(search_fields)) {
      const search_condition = this.create_search_condition(key, value, category);
      if (!this.utility.is_empty_object(source)) {
        this.push_value_to_and_condition(search_condition, source);
      }
      if (!this.utility.is_empty_object(geo)) {
        this.push_value_to_and_condition(search_condition, geo);
      }
      conditions_list.push(search_condition);
    }
    query_spec["logicalOperator"] = search_data.operator;
    // In the case of a multimedia search, we retrieve 12 items instead of the default 10.
    query_spec["size"] = category == "multimedia" ? environment.multimediaSearchListSize : search_data.size;
    query_spec["conditions"] = conditions_list;

    // Now of course we can only search on source or geo. If this is the case, we need to push these
    // conditions to the conditions list, as they cannot be added to the AND fields
    if (conditions_list.length == 0) {
      // AND, as the GEO and SOURCE data should both be in the results
      query_spec["logicalOperator"] = "AND";
      if (!this.utility.is_empty_object(source) && !this.utility.is_empty_object(geo)) {
        query_spec["conditions"].push(source);
        query_spec["conditions"].push(geo);
      } else if (!this.utility.is_empty_object(geo)) {
        query_spec["conditions"].push(geo);
      } else if (!this.utility.is_empty_object(source)) {
        query_spec["conditions"].push(source);
      }
    }
    return query_spec;
  }

  public create_geo_locations_query_spec(): any {
    const query_spec = {
      conditions: [],
      logicalOperator: "AND",
      size: environment.maxGeoResults,
      fields: ["sourceSystemId", "areaType", "locality", "countryNL"],
    };
    return query_spec;
  }

  private create_taxa_query_spec(search_data: Search_data): any {
    const query_spec = this.new_query_spec();
    const condition = this.create_query_condition(
      //"identifications.scientificName.fullScientificName",
      "identifications.scientificName.scientificNameGroup",
      "EQUALS_IC",
      search_data.search_term.replaceAll("_", " "),
      environment.specimensDenominator,
    );
    query_spec["conditions"].push(condition);
    query_spec["conditions"].push(this.data_source_systems);
    query_spec["logicalOperator"] = "AND";
    query_spec["size"] = search_data.size;
    return query_spec;
  }

  private create_specimen_item_query_spec(search_data: Search_data): any {
    const query_spec = this.new_query_spec();
    const condition = this.create_query_condition(
      "sourceSystemId",
      "EQUALS_IC",
      search_data.endpoint.replaceAll("_", " "),
      environment.specimensDenominator,
    );
    query_spec["conditions"].push(condition);
    query_spec["conditions"].push(this.data_source_systems);
    query_spec["logicalOperator"] = "AND";
    query_spec["size"] = search_data.size;
    return query_spec;
  }

  private create_taxon_item_query_spec(search_data: Search_data | any): any {
    const query_spec = this.new_query_spec();
    const condition = this.create_query_condition(
      "acceptedName.scientificNameGroup",
      "EQUALS_IC",
      search_data.endpoint.replaceAll("_", " "),
      "taxa",
    );
    query_spec["conditions"].push(condition);
    query_spec["conditions"].push(this.data_source_systems);
    query_spec["logicalOperator"] = "AND";
    query_spec["size"] = search_data.size;
    return query_spec;
  }

  private create_multimedia_item_query_spec(search_data: Search_data): any {
    const query_spec = this.new_query_spec();
    const condition = this.create_query_condition(
      "sourceSystemId",
      "EQUALS_IC",
      search_data.endpoint,
      environment.multimediaDenominator,
    );
    query_spec["conditions"].push(condition);
    query_spec["conditions"].push(this.data_source_systems);
    query_spec["logicalOperator"] = "AND";
    query_spec["size"] = search_data.size;
    return query_spec;
  }

  /**
   * Retrieve the linked assemblage given the specimen registration number
   * @param registration_number (string), identifies specimen
   * @author Luuk
   */
  public get_linked_assemblage_query_spec(registration_number: string): any {
    const query_spec = structuredClone(this.linked_assemblage_template);
    query_spec["conditions"][0].value = registration_number;
    return query_spec;
  }

  /**
   * Retrieve the linked multimedia given the specimen registration_number
   * @param registration_number string, identifies specimen
   * @author Luuk
   */
  public get_linked_multimedia_query_spec(registration_number: string): any {
    const query_spec = structuredClone(this.linked_media_template);
    query_spec["conditions"][0].value = registration_number;
    return query_spec;
  }
}
